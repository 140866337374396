// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
// Internal module imports
import AfrrClasses from './index.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import classes from '../../../../styles/AllDevices.module.css';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import { momentTimeFormater } from '../../../../utils/timeHelper';

const LIMIT = 15;

const EicDataLogs = ({ queryParamsData, updateQueryParams, eicCodeOptions }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [selectedEicCode, setSelectedEicCode] = useState({});
    const [tableData, setTableData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (eicCodeOptions?.length) setSelectedEicCode(queryParamsData.eicCode ? { value: queryParamsData.eicCode } : eicCodeOptions[0]);
    }, [eicCodeOptions]);

    useEffect(() => {
        if (selectedEicCode?.value) {
            getEicLogs();
        }
    }, [selectedEicCode, skip]);

    const getEicLogs = () => {
        const params = {
            eicCode: selectedEicCode.value,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            startTime: startTime,
            endTime: endTime,
            skip: skip,
            limit: LIMIT,
        };
        RootAfrrService.GetEicLogs(params, startLoader, handleLogsSuccess, handleError, stopLoader);
    };

    const handleLogsSuccess = ({ data }) => {
        const processedData = data.data.logs.map((item) => ({
            timestamp: momentTimeFormater(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            dk1H: _.get(item, ['picassoHeartbeatSignalDk1', 'value'], '--'),
            dk1SR: _.get(item, ['picassoSetpointRequestedActivationDk1', 'value'], '--'),
            dk1SRF: _.get(item, ['picassoSetpointRequestedActivationFeedbackDk1', 'value'], '--'),
            dk1AAP: _.get(item, ['picassoAbsoluteActivationSignalDk1', 'value'], '--'),
            dk1CAP: _.get(item, ['picassoCurrentActivationSignalDk1', 'value'], '--'),
            dk2H: _.get(item, ['picassoHeartbeatSignalDk2', 'value'], '--'),
            dk2SR: _.get(item, ['picassoSetpointRequestedActivationDk2', 'value'], '--'),
            dk2SRF: _.get(item, ['picassoSetpointRequestedActivationFeedbackDk2', 'value'], '--'),
            dk2AAP: _.get(item, ['picassoAbsoluteActivationSignalDk2', 'value'], '--'),
            dk2CAP: _.get(item, ['picassoCurrentActivationSignalDk2', 'value'], '--'),
        }));
        setTableData(processedData);
        setTotalCount(data.data.count);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <div>
                <div className={AfrrClasses.Filter}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={AfrrClasses.FilterContainer}>
                            <div>
                                <div className={classes.FieldControl}>
                                    <label>Select EIC Code</label>
                                    <DropdownComponent
                                        name="eicCode"
                                        options={eicCodeOptions}
                                        defaultValue={selectedEicCode}
                                        onChange={(e) => {
                                            if (e.value !== selectedEicCode?.value) {
                                                setSelectedEicCode(e);
                                            }
                                            updateQueryParams('eicCode', e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={AfrrClasses.FilterContainer}>
                            <div className={AfrrClasses.LeftContainer}>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>Start Time</label>
                                    <CustomTimePicker
                                        startTime={startTime}
                                        setStartTime={(e) => {
                                            setStartTime(e);
                                            updateQueryParams('startTime', e);
                                        }}
                                        type="start"
                                        maxDetail={'second'}
                                    />
                                </div>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>End Time</label>
                                    <CustomTimePicker
                                        endTime={endTime}
                                        setEndTime={(e) => {
                                            setEndTime(e);
                                            updateQueryParams('endTime', e);
                                        }}
                                        type="end"
                                        maxDetail={'second'}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '0.5vw' }}>
                                    <div onClick={getEicLogs}>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.AllDevices}>
                    <div className={classes.Header}>
                        <div>
                            <Typography content="EIC Values" />
                            <div className={classes.TableCount}>
                                Total Count :
                                <span>
                                    <Typography size="14" content={totalCount || 0} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Table
                                head={[
                                    'Timestamp (CET)',
                                    'DK1 heartbeat',
                                    'DK1 setpoint requested',
                                    'DK1 Setpoint Requested Feedback',
                                    'DK1 Absolute Activation Power',
                                    'DK1 Current Activation Power',
                                    'DK2 Heartbeat',
                                    'DK2 Setpoint Requested',
                                    'DK2 Setpoint Requested Feedback',
                                    'DK2 Absolute Activation Power',
                                    'DK2 Current Activation Power',
                                ]}
                                keys={['timestamp', 'dk1H', 'dk1SR', 'dk1SRF', 'dk1AAP', 'dk1CAP', 'dk2H', 'dk2SR', 'dk2SRF', 'dk2AAP', 'dk2CAP']}
                                data={tableData}
                                page={page}
                                Pagination={true}
                                limit={LIMIT}
                                handlePageChange={handlePageChange}
                                totalCount={totalCount}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EicDataLogs;
