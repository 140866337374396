// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { flatten } from 'flat';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import { getCETDate, momentTimeFormater, timeOnly } from '../../../../utils/dateHelper';
import { useLoader } from '../../../../hooks';
import { activationProfileService } from '../../../../services/ActivationProfileService';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { usToEuCurrencyFormat } from '../../../../utils/currencyHelper';
import ChartComponent from '../../../../components/ApexCharts/Chart';

// External library imports
import { Menu, MenuItem } from '@szhsin/react-menu';

// Css imports
import activationClasses from '../ActivationProfiler.module.css';
import { AFRR_MARKET_TYPES } from '../../../../constants';

const DataLogModel = ({ setOpen, dataLogModal, profile = null }) => {
    const [startLoader, stopLoader] = useLoader();
    const [frequencyData, setFrequencyData] = useState([]);
    const [powerData, setPowerData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (dataLogModal.id && profile.market) {
            activationProfileService.getDataLog(dataLogModal.id, { type: profile.market }, startLoader, handledataLogSuccess, handleError, stopLoader);
        }
    };

    const handledataLogSuccess = ({ data }) => {
        setPowerData(data.data.powerGraph);
        setFrequencyData(data.data.frequencyGraph);
    };

    const getDownloadData = (prefillData) => {
        activationProfileService.getDataLog(dataLogModal.id, { type: profile.market, prefillData: prefillData }, startLoader, handleDownloadDataSuccess, handleError, stopLoader);
    };

    const handleDownloadDataSuccess = ({ data }) => {
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = data.data.downloadable
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }
        DownloadAsExcel(flattenedData, `${dataLogModal.meta.groupName} DataLog - ${dataLogModal.meta.startTime}`, columnNames, columnIndex);
    };

    const handleError = (err) => {
        console.log(err);
    };
    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };
    return (
        <div style={{ width: '50vw', maxHeight: '90vh' }}>
            <Typography content={'Data Log'} />
            <div className={activationClasses.ButtonContainer2}>
                <Menu
                    menuButton={
                        <div>
                            <DownloadButton size={'medium'} />
                        </div>
                    }
                    direction={'bottom'}
                    arrow={true}
                    menuClassName={activationClasses.Menu}
                >
                    <MenuItem onClick={() => getDownloadData(false)} className={activationClasses.MenuOptions}>
                        <div>Raw Download</div>
                    </MenuItem>
                    <MenuItem onClick={() => getDownloadData(true)} className={activationClasses.MenuOptions}>
                        <div>Advance Download</div>
                    </MenuItem>
                </Menu>
            </div>
            <div>
                <div>
                    <ChartComponent
                        title={AFRR_MARKET_TYPES.includes(profile?.market) ? 'Set Point' : 'Frequency (Hz)'}
                        strokeWidth={2}
                        type="line"
                        colors={['var(--color-primary)']}
                        series={frequencyData}
                        xFormatter={timeOnly}
                        yFormatter={formatYAxisValue}
                        chartProps={{ group: 'dataLog' }}
                    />
                </div>
                <div>
                    <ChartComponent
                        title="Power (kW)"
                        strokeWidth={2}
                        type="line"
                        colors={['var(--color-primary)']}
                        series={powerData}
                        xFormatter={timeOnly}
                        yFormatter={formatYAxisValue}
                        chartProps={{ group: 'dataLog' }}
                    />
                </div>
            </div>
            <div className={activationClasses.ButtonContainer}>
                <div className="btn-secondary" onClick={setOpen}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default DataLogModel;
