// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import Table from '../../../../components/Table/Table';
import { UilInfoCircle } from '@iconscout/react-unicons';
import moment from 'moment/moment';
import { InvoiceServiceRoot } from '../../../../services/RootInvoiceService';
import CkeEditor from '../../../../components/CkeEditor';
import { Form, Formik } from 'formik';
import { momentTimeFormater } from '../../../../utils/timeHelper';

// Internal module imports
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';

const LIMIT = 15;

const InvoiceHistory = () => {
    const [startLoader, stopLoader] = useLoader();
    const [invoiceData, setInvoiceData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: '' });

    useEffect(() => {
        getInvoices();
    }, [skip]);

    const getInvoices = () => {
        InvoiceServiceRoot.getInvoices(
            {
                limit: LIMIT,
                skip: skip,
            },
            startLoader,
            handleGetInvoicesSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetInvoicesSuccess = ({ data }) => {
        const processedData = data.data?.data.map((item) => {
            return {
                accountName: item.invoiceTypeValue,
                accountType: item.invoiceType,
                month: moment(item.date).format('MMMM'),
                emailType: item.emailType,
                sentAt: momentTimeFormater(item.createdAt).format('YYYY-MM-DD'),
                emails: (<CustomTooltip content={item.emails.join(", ")}>
                    <div>{item.emails.join(", ").substring(0, 40) + '...'}</div>
                </CustomTooltip>),
                preview: (
                    <CustomTooltip content={'preview'}>
                        <UilInfoCircle
                            style={{
                                color: 'var(--color-primary)',
                            }}
                            size={'1.3vw'}
                            onClick={() => setInfoModal({ status: true, data: item.emailBody })}
                        />
                    </CustomTooltip>
                )
            }
        });

        setInvoiceData(processedData);
        setTotalCount(data.data?.count ? data.data.count : 0);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div>
            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: '' })}>
                <div style={{ maxWidth: "80vw", maxHeight: '90vh', paddingBottom: '1vw' }}>
                    <Typography content={'Invoice Preview'} />

                    <Formik initialValues={{ emailBody: infoModal.data }}>
                        {() => {
                            return (
                                <Form>
                                    <div className={classes.FieldControl2}>
                                        <label for="emailBody">
                                            Email Body
                                        </label>
                                        <CkeEditor
                                            name="emailBody"
                                            defaultValue={infoModal.data}
                                            disabled={true}
                                        ></CkeEditor>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="button" className="btn-secondary" onClick={() => setInfoModal({ status: false, data: '' })}>
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>

            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Invoices Sent History" />
                    </div>
                </div>
                <div>
                    <Table
                        head={['Account Name', 'Account Type', 'Month', 'Email Type', 'Emails', 'Sent At', 'Preview']}
                        keys={['accountName', 'accountType', 'month', 'emailType', 'emails', 'sentAt', 'preview']}
                        data={invoiceData}
                        page={page}
                        Pagination={true}
                        limit={LIMIT}
                        handlePageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>
            </div>
        </div>
    );
};

export default InvoiceHistory;
