// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import Table from '../../../../components/Table/Table';
import { UilMoneyInsert, UilHistory, UilEnvelopeEdit, UilMessage } from '@iconscout/react-unicons';
import moment from 'moment/moment';
import MonthPicker from '../../../../components/Inputs/MonthPicker/MonthPicker';
import { InvoiceServiceRoot } from '../../../../services/RootInvoiceService';
import CkeEditor from '../../../../components/CkeEditor';
import { Form, Formik } from 'formik';
import { momentTimeFormater } from '../../../../utils/timeHelper';

// Internal module imports
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import Checkbox from '../../../../components/Inputs/Checkbox/Checkbox';
import { toDkFormatter } from '../../../../utils/currencyHelper';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import invoiceClasses from './index.module.css';

const ACCOUNT_TYPE = [
    {
        label: 'Partners',
        value: 'admin',
    },
    {
        label: 'Enablers',
        value: 'enabler',
    },
    {
        label: 'Customers',
        value: 'customer',
    },
];

const LANGUAGE_OPTIONS = [
    { label: 'Danish', value: 'da' },
    { label: 'English', value: 'en' },
];

const monthNamesDanish = [
    "januar", "februar", "marts", "april", "maj", "juni",
    "juli", "august", "september", "oktober", "november", "december",
];

const getEmailBodyDanish = ({
    accountTypeValue,
    date,
    revenue,
}) => {
    const updatedDate = momentTimeFormater(date).add(2, 'months').endOf('month');

    // Get the day, month, and year
    const day = updatedDate.format('DD');
    const monthDanish = monthNamesDanish[updatedDate.month()]; // Map the month index to Danish month names
    const year = updatedDate.format('YYYY');

    // Format the date
    const twoMonthForwardDanish = `${day}. ${monthDanish}. ${year}`;

    return `<html>
       <body style="font-family: Roboto-Regular,Arial,sans-serif; margin: 0; padding: 10px 0; background: #EDEFF4;">
          <h1 style="color: #1B9F78;"> &nbsp;&nbsp;&nbsp;&nbsp;</h1>
          <div style="width: 100%; margin: auto; max-width: 620px; background: #FFFFFF;border-radius: 32px;">
             <div style="padding: 30px">
                <p style="line-height: 24px; color: #1B1D21; ">${accountTypeValue}</p>
                <br/>
                <br/>
                
                <span style="line-height: 24px; color: #1B1D21;">
                Kære kunde,</span>
                
                <p style="color: #505862; font-size: 15px;">S.C. Nordic A/S har opgjort udligningen af elnettet for ${monthNamesDanish[momentTimeFormater(date).month()]} ${momentTimeFormater(date).format('YYYY')}, og i denne forbindelse bedes I sende os 1 stk. faktura på nedenstående beløb med betaling ${twoMonthForwardDanish}.</p>
                
                <br/>
                
                <p style="line-height: 24px; color: #1B1D21; ">Udligning ${momentTimeFormater(date).format('YYYY')}.${momentTimeFormater(date).format('MM')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${revenue} DKK hvortil tillægges moms.</p>
               
                <br/>
                
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Best regards / Med venlig hilsen </p>
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Flemming Heiberg </p>
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Email: regnskab@scnordic.com </p>
                
                <br/>
                
                <figure class="image" style="float: left; width: 50vw; margin-right: 10px;">
                    <img src="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/invoiceEmailBody/invoiceEmailBody.png" style="width: 100%; height: auto;">
                </figure>
             </div>
          </div>
       </body>
    </html>`;
};

const getEmailBodyEnglish = ({
    accountTypeValue,
    date,
    revenue,
}) => {
    const updatedDate = momentTimeFormater(date).add(2, 'months').endOf('month');

    // Get the day, month, and year
    const day = updatedDate.format('DD');
    const monthEnglish = updatedDate.format('MMMM');
    const year = updatedDate.format('YYYY');

    // Format the date
    const twoMonthForwardEnglish = `${day}. ${monthEnglish}. ${year}`.toLowerCase();

    return `<html>
       <body style="font-family: Roboto-Regular,Arial,sans-serif; margin: 0; padding: 10px 0; background: #EDEFF4;">
          <h1 style="color: #1B9F78;"> &nbsp;&nbsp;&nbsp;&nbsp;</h1>
         <div style="width: 100%; margin: auto; max-width: 620px; background: #FFFFFF;border-radius: 32px;">
             <div style="padding: 30px">
                <p style="line-height: 24px; color: #1B1D21; ">${accountTypeValue}</p>
                
                <br/>
                <br/>
                
                <span style="line-height: 24px; color: #1B1D21;">
                Dear Customer,</span>
                
                <p style="color: #505862; font-size: 15px;">S.C. Nordic A/S has calculated the equalization of the electricity grid for ${momentTimeFormater(date).format('MMMM').toLowerCase()} ${momentTimeFormater(date).format('YYYY')}, where you can sent an invoice with due date on the ${twoMonthForwardEnglish}.</p>
                
                <br/>
                
                <p style="line-height: 24px; color: #1B1D21; ">Equalization ${momentTimeFormater(date).format('YYYY')}.${momentTimeFormater(date).format('MM')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${revenue} DKK +VAT</p>
               
                <br/>
                
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Best regards / Med venlig hilsen </p>
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Flemming Heiberg </p>
                <p style="color: #505862; font-size: 15px; margin-bottom: 7px">Email: regnskab@scnordic.com </p>
                
                <br/>
                
                <figure class="image" style="float: left; width: 50vw; margin-right: 10px;">
                    <img src="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/invoiceEmailBody/invoiceEmailBody.png" style="width: 100%; height: auto;">
                </figure>
             </div>
          </div>
       </body>
    </html>`;
};

const Invoice = ({ queryParamsData, updateQueryParams, setHistoryTab }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(
        queryParamsData.date
            ? new Date(queryParamsData.date)
            : new Date()
    );
    const [selectedAccountType, setSelectedAccountType] = useState({});
    const [invoiceData, setInvoiceData] = useState([]);
    const [previewModal, setPreviewModal] = useState({
        status: false,
        data: '',
        updatedEmailBody: ''
    });
    const [handleSubmitState, setHandleSubmitState] = useState({
        status: false,
        data: {}
    });

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getRevenue();
    }, [selectedAccountType, date]);

    useEffect(() => {
        if (handleSubmitState.status) {
            handleSubmit(handleSubmitState.data.type, handleSubmitState.data.index);
            setHandleSubmitState({ status: false, data: {} });
        }
    }, [handleSubmitState.status])

    const handleSubmit = (emailType = 'invoiceEmail', index = 'all') => {
        if (invoiceData.length) {
            let payload = [];

            if (index !== 'all') {
                const value = invoiceData[index];

                payload.push({
                    language: value.language,
                    emailBody: value.language === 'da' ? value.emailBodyDanish : value.emailBodyEnglish,
                    invoiceType: selectedAccountType.value,
                    invoiceTypeValue: value.name,
                    invoiceTypeId: value._id,
                    revenue: value.revenueEuro,
                    date: moment(date),
                    emailType
                });
            } else {
                payload = invoiceData.filter((item) => item.isChecked).map((value) => {
                    return {
                        language: value.language,
                        emailBody: value.language === 'da' ? value.emailBodyDanish : value.emailBodyEnglish,
                        invoiceType: selectedAccountType.value,
                        invoiceTypeValue: value.name,
                        invoiceTypeId: value._id,
                        revenue: value.revenueEuro,
                        date: moment(date),
                        emailType
                    }
                });
            }

            InvoiceServiceRoot.sendInvoice({ invoices: payload }, startLoader, handleCreateSuccess, handleError, stopLoader);
        }
    };

    const handleCreateSuccess = ({ data }) => {
        toast.success('Invoice Sent Successfully');
        getRevenue();
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const getChecked = (e, bid) => {
        setInvoiceData((prev) =>
            prev.map((item) =>
                item._id === bid._id
                    ? {
                        ...item,
                        isChecked: e,
                        select: (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Checkbox onChange={(e) => getChecked(e, { ...item, isChecked: e })} checked={e} />
                            </div>
                        ),
                    }
                    : item
            )
        );
    };

    const getSelectedLanguage = (e, selected) => {
        setInvoiceData((prev) =>
            prev.map((item) =>
                item._id === selected._id
                    ? {
                        ...item,
                        language: e.value,
                        languageOption: (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                < DropdownComponent
                                    name="language"
                                    options={LANGUAGE_OPTIONS}
                                    defaultValue={{ value: e }}
                                    onChange={(e) => {
                                        getSelectedLanguage(e, item);
                                    }}
                                />
                            </div>
                        )
                    }
                    : item
            )
        );
    };

    const handleGetRevenueSuccess = ({ data }) => {
        const processedData = data?.data.map((item, index) => {
            const revenue = item.revenueDKK ? toDkFormatter(item.revenueDKK.toFixed(2)) : 0;
            const emailBodyDanish = getEmailBodyDanish({
                accountTypeValue: item.name,
                date,
                revenue,
            });
            const emailBodyEnglish = getEmailBodyEnglish({
                accountTypeValue: item.name,
                date,
                revenue,
            });
            const language = LANGUAGE_OPTIONS[0].value;

            return {
                ...item,
                index,
                name: item.name,
                revenueEuro: item.revenue.toFixed(2),
                revenue,
                language,
                emailBodyEnglish,
                emailBodyDanish,
                languageOption: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <DropdownComponent
                            name="language"
                            options={LANGUAGE_OPTIONS}
                            defaultValue={LANGUAGE_OPTIONS[0]}
                            onChange={(e) => {
                                getSelectedLanguage(e, item);
                            }}
                        />
                    </div>
                ),
                isChecked: false,
                select: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Checkbox onChange={(e) => getChecked(e, item)} checked={item?.isChecked} />
                    </div>
                ),
                invoiceSent: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {item.sentInvoice ? <div className={classes.AccessNotBlocked}></div> : <div className={classes.AccessBlocked}></div>}
                    </div>
                ),
                action: (
                    <div className={invoiceClasses.ActionContainer}>
                        <div>
                            <CustomTooltip content={'preview / Edit'}>
                                <UilEnvelopeEdit
                                    style={{
                                        color: 'var(--color-primary)',
                                    }}
                                    size={'1.3vw'}
                                    onClick={() => {
                                        setPreviewModal({
                                            status: true,
                                            data: index,
                                            updatedEmailBody: ''
                                        });
                                    }}
                                />
                            </CustomTooltip>
                        </div>
                        <div >
                            <CustomTooltip content={'Add to Credit Note'}>
                                <UilMoneyInsert
                                    style={{
                                        color: 'var(--color-primary)',
                                    }}
                                    size={'1.3vw'}
                                    onClick={() => {
                                        setHandleSubmitState({
                                            status: true,
                                            data: {
                                                type: 'creditEmail',
                                                index
                                            }
                                        });
                                    }}
                                />
                            </CustomTooltip>
                        </div>
                        <div>
                            <CustomTooltip content={'Send Invoice'}>
                                <UilMessage
                                    style={{
                                        color: 'var(--color-primary)',
                                    }}
                                    size={'1.3vw'}
                                    onClick={() => {
                                        setHandleSubmitState({
                                            status: true,
                                            data: {
                                                type: 'invoiceEmail',
                                                index
                                            }
                                        });
                                    }}
                                />
                            </CustomTooltip>
                        </div>
                    </div>
                )
            }
        });

        setInvoiceData(processedData);
    };

    const getRevenue = () => {
        InvoiceServiceRoot.GetRevenue(
            {
                type: selectedAccountType.value,
                date: moment(date).format('YYYY-MM-DD'),
            },
            startLoader,
            handleGetRevenueSuccess,
            handleError,
            stopLoader
        );
    };

    const updateEmailBody = (data) => {
        setInvoiceData((prev) =>
            prev.map((item, index) =>
                previewModal.data === index
                    ? {
                        ...item,
                        ...(item.language === 'en' ?
                            { emailBodyEnglish: previewModal.updatedEmailBody } :
                            { emailBodyDanish: previewModal.updatedEmailBody })
                    }
                    : item
            )
        );
        setPreviewModal({
            status: false,
            data: '',
            updatedEmailBody: ''
        });
    };

    return (
        <div>
            <ModalComponent isOpen={previewModal.status} setOpen={() => setPreviewModal({
                status: false,
                data: '',
                updatedEmailBody: ''
            })}>
                <div style={{ maxWidth: "80vw", maxHeight: '90vh', paddingBottom: '1vw' }}>
                    <Typography content={'Invoice Preview'} />

                    <Formik initialValues={{
                        emailBody:
                            previewModal.status && invoiceData[previewModal.data]?.language === 'en' ?
                                invoiceData[previewModal.data]?.emailBodyEnglish :
                                invoiceData[previewModal.data]?.emailBodyDanish,
                    }} enableReinitialize onSubmit={(data) => updateEmailBody(data)}>
                        {() => {
                            return (
                                <Form>
                                    <div className={classes.FieldControl2}>
                                        <label for="emailBody">
                                            Email Body
                                        </label>
                                        <CkeEditor
                                            name="emailBody"
                                            defaultValue={
                                                previewModal.status &&
                                                    invoiceData[previewModal?.data]?.language === 'en' ?
                                                    invoiceData[previewModal.data]?.emailBodyEnglish :
                                                    invoiceData[previewModal.data]?.emailBodyDanish
                                            }
                                            onChange={(e) => setPreviewModal({
                                                ...previewModal,
                                                updatedEmailBody: e
                                            })}
                                        ></CkeEditor>
                                        <div className={classes.ButtonContainer} style={{ paddingBottom: '1vw' }}>
                                            <div>
                                                <button type="button" className="btn-secondary" onClick={() => setPreviewModal({
                                                    status: false,
                                                    data: '',
                                                    updatedEmailBody: ''
                                                })}>
                                                    Close
                                                </button>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary" >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <div className={invoiceClasses.Filter}  >
                <div className={classes.Header}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={invoiceClasses.FilterContainer}>
                            <div>
                                <div className={classes.FieldControl}>
                                    <label>Account Type</label>
                                    <DropdownComponent
                                        name="accountType"
                                        options={ACCOUNT_TYPE}
                                        defaultValue={queryParamsData.accountType ? JSON.parse(queryParamsData.accountType) : ACCOUNT_TYPE[0]}
                                        onChange={(e) => {
                                            setSelectedAccountType(e);
                                            updateQueryParams('accountType', JSON.stringify(e));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={invoiceClasses.FilterRight}>
                        <div className={invoiceClasses.DatePickers}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={invoiceClasses.DateLabels}>Month</div>
                                </div>
                                <MonthPicker date={date} setDate={(e) => setDate(e)} />
                            </div>
                        </div>
                        <div>
                            <CustomTooltip content={'Invoices Sent History'}>
                                < UilHistory
                                    style={{
                                        color: 'var(--color-primary)',
                                        cursor: 'pointer'
                                    }}
                                    size={'1.5vw'}
                                    onClick={() => setHistoryTab(true)}
                                />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Revenue" />
                    </div>
                </div>
                <div>
                    <Table
                        head={['Select', 'Name', 'Revenue (DKK)', 'Invoice Sent', 'Language', 'Action']}
                        keys={['select', 'name', 'revenue', 'invoiceSent', 'languageOption', 'action']}
                        data={invoiceData}
                    />
                    <div className={invoiceClasses.DatePickers}>
                        <div>
                            <div>
                                <button
                                    type="submit"
                                    className={`btn-primary`}
                                    disabled={!invoiceData.some((item) => item.isChecked === true)}
                                    style={{ marginTop: '0' }}
                                    onClick={() => {
                                        handleSubmit('invoiceEmail', 'all');
                                    }}
                                >
                                    Send Invoices
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
