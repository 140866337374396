// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import classes from '../Economics.module.css';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import HourlyCost from '../HourlyCost';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { useLoader } from '../../../../hooks';
import { RootEconomicsService } from '../../../../services/EconomicsService';
import { Capitalize } from '../../../../utils/stringHelper';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import { AdminService } from '../../../../services/AdminService';
import creditsClassess from '../Credits/index.module.css';
import { LicenseTypeService } from '../../../../services/licenseTypeService';

const LIMIT = 15;

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const Details = ({ admins: adminsData = [], queryParamsData, updateQueryParams }) => {
    const defaultValue = {
        label: 'All',
        value: 'All',
    };

    const [startLoader, stopLoader] = useLoader();
    const date = new Date();
    const [dateRange, setDateRange] = useState(
        queryParamsData.date
            ? {
                  startDate: new Date(JSON.parse(queryParamsData.date).startDate),
                  endDate: new Date(JSON.parse(queryParamsData.date).endDate),
              }
            : {
                  startDate: new Date(date.getFullYear(), date.getMonth(), 1),
                  endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
              }
    );
    const [tableData, setTableData] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [TotalRevenue, setTotalRevenue] = useState({
        totalRevenue: 0,
        totalRevenueSc: 0,
        totalRevenueCustomer: 0,
        totalRevenuePartner: 0,
        totalRevenueEnabler: 0,
    });
    const [Revenue, setRevenue] = useState(0);
    const [hourlyModal, setHourlyModal] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : defaultValue);
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(queryParamsData.customer ? JSON.parse(queryParamsData.customer) : defaultValue);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [licenseOptions, setLicenseOptions] = useState([defaultValue]);
    const [selectedLicense, setSelectedLicense] = useState(queryParamsData.license ? JSON.parse(queryParamsData.license) : {});
    const [instanceOptions, setInstanceOptions] = useState([defaultValue]);
    const [selectedInstance, setSelectedInstance] = useState(queryParamsData.instance ? JSON.parse(queryParamsData.instance) : {});
    const [granularity, setGranularity] = useState(
        queryParamsData.granularity
            ? JSON.parse(queryParamsData.granularity)
            : {
                  label: 'Hours',
                  value: 'hours',
              }
    );
    const [isParams, setIsParams] = useState(queryParamsData.instance ? true : false);

    const granularityOptions = [
        { label: 'Hours', value: 'hours' },
        { label: 'Days', value: 'days' },
    ];

    useEffect(() => {
        updateQueryParams('date', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        if (Array.isArray(adminsData) && adminsData.length) {
            const adminOptions = getAdminOptions(adminsData);
            setAdmins(adminOptions);
        } else {
            setAdmins([]);
        }
    }, [JSON.stringify(adminsData)]);

    useEffect(() => {
        if (admin?.value) {
            if (admin.value === 'All') {
                setCustomers([defaultValue]);
                setCustomer(defaultValue);
            } else {
                getCustomers();
            }
        }
    }, [admin]);

    useEffect(() => {
        if (customer?.value && customer?.value !== 'All') {
            getLicense();
        } else {
            setSelectedLicense({ label: 'All', value: 'All' });
            setLicenseOptions([{ label: 'All', value: 'All' }]);
        }
    }, [customer]);

    useEffect(() => {
        if (selectedLicense?.value === 'All') {
            if (!isParams) {
                setSelectedInstance({ label: 'All', value: 'All' });
            }
            setInstanceOptions([{ label: 'All', value: 'All' }]);
        } else if (selectedLicense?.value && selectedLicense?.value !== 'All') {
            getInstance();
        }
    }, [selectedLicense]);

    useEffect(() => {
        fetchTableData(true);
        setPage(0);
    }, [selectedInstance]);

    useEffect(() => {
        fetchTableData();
    }, [skip]);

    const fetchTableData = (skipNone = false) => {
        if (admin.value && selectedInstance?.value) {
            const processedFilter = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                adminId: admin.value,
                limit: LIMIT,
                skip: skipNone ? 0 : skip,
                customerId: customer.value,
                instanceId: selectedInstance?.value,
                licenseId: selectedLicense?.value,
                granularity: granularity?.value,
            };

            RootEconomicsService.Read(processedFilter, startLoader, handleEconomicDataSuccess, handleError, stopLoader);
        }
    };

    const handleEconomicDataSuccess = ({ data }) => {
        const newData = [...data.data.exportData];
        setExcelData(newData);
        const processedData = data.data.data.map((item) => {
            return {
                ...item,
                date: moment(item.date).format('DD-MM-YYYY'),
                capacity: parseFloat(item.capacity).toLocaleString('da-DK'),
                sc: parseFloat(item.sc).toLocaleString('da-DK'),
                partnerRevenue: parseFloat(item.partnerRevenue).toLocaleString('da-DK'),
                customerRevenue: parseFloat(item.customerRevenue).toLocaleString('da-DK'),
                enablersRevenue: parseFloat(item.enablers).toLocaleString('da-DK'),
                source: Capitalize(item.source),
                action: (
                    <div
                        onClick={() => {
                            setHourlyModal(item.bidStatus);
                            setRevenue(item.revenue);
                        }}
                    >
                        <div>
                            <a href="#" style={{ color: '#818e94' }}>
                                Details
                            </a>
                        </div>
                    </div>
                ),
            };
        });
        setTableData(processedData);
        setTotalRevenue({
            totalRevenue: Math.round(data.data.totalRevenue).toLocaleString('da'),
            totalRevenueSc: Math.round(data.data.totalRevenueSc).toLocaleString('da'),
            totalRevenueCustomer: Math.round(data.data.totalRevenueCustomer).toLocaleString('da'),
            totalRevenuePartner: Math.round(data.data.totalRevenuePartner).toLocaleString('da'),
            totalRevenueEnabler: Math.round(data.data.totalRevenueEnabler).toLocaleString('da'),
        });
        setTotalCount(data.data.count);
    };

    const getCustomers = () => {
        AdminService.GetCustomers(admin.value, startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [defaultValue];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setCustomers(temp);
        if (!isParams) {
            setCustomer(defaultValue);
        }
    };

    const getLicense = () => {
        LicenseTypeService.GetLicenseByCustomerId(customer.value, startLoader, handleLicenseSuccess, handleError, stopLoader);
    };

    const handleLicenseSuccess = ({ data }) => {
        let temp = [{ label: 'All', value: 'All' }];
        data.data.map((l) => {
            temp.push({ label: l.name, value: l._id });
        });
        setLicenseOptions(temp);
        if (!isParams) {
            setSelectedLicense({ label: 'All', value: 'All' });
        }
    };

    const getInstance = () => {
        LicenseTypeService.GetInstance({ customerId: customer.value, licenseId: selectedLicense?.value }, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleInstanceSuccess = ({ data }) => {
        let temp = [{ label: 'All', value: 'All' }];
        data.data.map((ins) => {
            temp.push({ label: ins.name, value: ins._id });
        });
        setInstanceOptions(temp);
        if (!isParams) {
            setSelectedInstance({ label: 'All', value: 'All' });
        }
    };

    const handleDownloadExcel = () => {
        let processExcelData = excelData.map((item) => ({
            date: moment(item.date).format('DD.MM.YYYY'),
            slot: item.timeSlot || '--',
            admin: item.admin,
            customer: item.customer,
            group: item.instance,
            capacity: Number(parseFloat(item.capacity).toFixed(6)),
            sc: Number(parseFloat(item.sc).toFixed(6)),
            partnerRevenue: Number(parseFloat(item.partnerRevenue).toFixed(6)),
            customerRevenue: Number(parseFloat(item.customerRevenue).toFixed(6)),
            enablerRevenue: Number(parseFloat(item.enablers).toFixed(6)),
            source: item.source,
            type: item.type,
        }));
        processExcelData.push({
            group: 'Total Revenue',
            SC: TotalRevenue.totalRevenue,
            type: 'Euro',
            source: '',
        });
        processExcelData.push({
            group: 'Total Revenue (SC)',
            SC: TotalRevenue.totalRevenueSc,
            type: 'Euro',
            source: '',
        });
        processExcelData.push({
            group: 'Total Revenue (Partner)',
            SC: TotalRevenue.totalRevenuePartner,
            type: 'Euro',
            source: '',
        });
        processExcelData.push({
            group: 'Total Revenue (Customer)',
            SC: TotalRevenue.totalRevenueCustomer,
            type: 'Euro',
            source: '',
        });
        processExcelData.push({
            group: 'Total Revenue (Enabler)',
            SC: TotalRevenue.totalRevenueEnabler,
            type: 'Euro',
            source: '',
        });
        processExcelData.length > 1 &&
            DownloadAsExcel(processExcelData, `Economics ${moment(dateRange.startDate).format('YYYY-MM-DD')}-${moment(dateRange.endDate).format('YYYY-MM-DD')}`, [
                'Date',
                'Slot',
                'Partner',
                'Customer',
                'Group',
                'Capacity (MW)',
                'SC (€)',
                'PARTNER (€)',
                'CUSTOMER (€)',
                'ENABLER (€)',
                'Source',
                'Type',
            ]);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={creditsClassess.Filter}>
                <div className={classes.FilterContainer} style={{ marginTop: '0' }}>
                    <div className={classes.DropdownContainer}>
                        <div className={classes.DropDnContainer}>
                            <label>Admin</label>
                            <DropdownComponent
                                name="adminId"
                                options={admins}
                                defaultValue={admin}
                                onChange={(e) => {
                                    if (e.value !== admin?.value) {
                                        setAdmin(e);
                                        setIsParams(false);
                                    }
                                    updateQueryParams('admin', JSON.stringify(e));
                                }}
                            />
                        </div>
                        <div className={classes.DropDnContainer}>
                            <label>Customer</label>
                            <DropdownComponent
                                name="customerId"
                                options={customers}
                                defaultValue={customer}
                                onChange={(e) => {
                                    if (e.value !== customer?.value) {
                                        setCustomer(e);
                                        setIsParams(false);
                                    }
                                    updateQueryParams('customer', JSON.stringify(e));
                                }}
                            />
                        </div>
                        <div className={classes.DropDnContainer}>
                            <label>License</label>
                            <DropdownComponent
                                name="license"
                                options={licenseOptions}
                                defaultValue={selectedLicense}
                                onChange={(e) => {
                                    if (e.value !== selectedLicense?.value) {
                                        setSelectedLicense(e);
                                        setIsParams(false);
                                    }
                                    updateQueryParams('license', JSON.stringify(e));
                                }}
                            />
                        </div>
                        <div className={classes.DropDnContainer}>
                            <label>Instance</label>
                            <DropdownComponent
                                name="instance"
                                options={instanceOptions}
                                defaultValue={selectedInstance}
                                onChange={(e) => {
                                    if (e.value !== selectedInstance?.value) {
                                        setSelectedInstance(e);
                                        setIsParams(false);
                                    }
                                    updateQueryParams('instance', JSON.stringify(e));
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.DatePickers}>
                        <div className={classes.DropDnContainer}>
                            <label>Granularity</label>
                            <DropdownComponent
                                name="granularity"
                                options={granularityOptions}
                                defaultValue={granularity}
                                onChange={(e) => {
                                    if (e.value !== granularity?.value) {
                                        setGranularity(e);
                                    }
                                    updateQueryParams('granularity', JSON.stringify(e));
                                }}
                            />
                        </div>
                        <div style={{ marginBottom: '0.5vh' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.DateLabels} style={{ marginBottom: '0.2vw' }}>
                                    Start Date
                                </div>
                                <div className={classes.DateLabels} style={{ marginRight: '2.604vw', marginBottom: '0.2vw' }}>
                                    End Date
                                </div>
                            </div>
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={(date) =>
                                    setDateRange({
                                        startDate: new Date(date.startDate),
                                        endDate: new Date(date.endDate),
                                    })
                                }
                            />
                        </div>

                        <div
                            style={{ marginBottom: '0.5vh' }}
                            className={classes.SubmitButton}
                            onClick={() => {
                                fetchTableData(true);
                                setPage(0);
                            }}
                        >
                            <button className="btn-primary">Submit</button>
                        </div>
                        <div onClick={() => handleDownloadExcel()} style={{ marginBottom: '0.5vh' }}>
                            <UilImport
                                size="1.5vw"
                                style={{
                                    cursor: 'pointer',
                                    marginTop: '0.4vw',
                                    color: 'var(--color-primary)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.Details}>
                <div className={classes.FilterContainer}>
                    <div className={classes.DropdownContainer}>
                        {/* <Typography content='FCR Bids' /> */}
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Revenue :
                                <span>
                                    <Typography size="14" content={TotalRevenue.totalRevenue + ' €'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Revenue (SC) :
                                <span>
                                    <Typography size="14" content={TotalRevenue.totalRevenueSc + ' €'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Revenue : (Partner)
                                <span>
                                    <Typography size="14" content={TotalRevenue.totalRevenuePartner + ' €'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Revenue : (Customer)
                                <span>
                                    <Typography size="14" content={TotalRevenue.totalRevenueCustomer + ' €'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Revenue : (Enablers)
                                <span>
                                    <Typography size="14" content={TotalRevenue.totalRevenueEnabler + ' €'} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.TableCountWrap}>
                            <div className={classes.TableCount}>
                                Total Count :
                                <span>
                                    <Typography size="14" content={totalCount || 0} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Table
                    head={['Date', 'Timeslot', 'Partner', 'Customer', 'Instance', 'Capacity (MW)', 'SC (€)', 'Partner (€)', 'Customer (€)', 'Enablers (€)', 'Energy Price', 'Type']}
                    keys={['date', 'timeSlot', 'admin', 'customer', 'instance', 'capacity', 'sc', 'partnerRevenue', 'customerRevenue', 'enablersRevenue', 'energyPrice', 'type']}
                    data={[...tableData]}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
                <ModalComponent isOpen={hourlyModal} setOpen={setHourlyModal}>
                    <HourlyCost revenue={Revenue} data={hourlyModal} setOpen={setHourlyModal} />
                </ModalComponent>
            </div>
        </div>
    );
};

export default Details;
