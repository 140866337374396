// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import { Route, Switch, useHistory } from 'react-router-dom';
import { get as lodashGet } from 'lodash';
import { UilTimesCircle } from '@iconscout/react-unicons';

// Internal module imports
import { PROTECTED_ROUTES, PROTECTED_ROUTES_ENABLER, PROTECTED_ROUTES_ROOT } from '../../routes/Routes';
import { AuthContext } from '../../context/AuthContext';
import { REMOVE_USER, START_LOADER, STOP_LOADER } from '../../constants';
import LogoutIcon from '../../assets/logout1.svg';
import { LoaderContext } from '../../context/LoaderContext';
import { UserService } from '../../services/UserService';
import SCLogo from '../../assets/new-sc-logo-no-background.svg';
import profile from '../../assets/profile.jpg';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import Typography from '../../components/Typography/Typography';
import { Password } from '../../components/Inputs/Input';
import { CustomerPasswordValidation } from '../../validations/Customer/PasswordValidation';
import { getUsers, removeAllUsers, removeUser } from '../../utils/localStorage';
import AddUserIcon from '../../assets/sidebar/AddUser.svg';
import LogoutAllIcon from '../../assets/sidebar/LogoutAll.svg';
import NotFound from '../ErrorPages/NotFound';
import Clock from './Clock';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';

// Css module imports
import classes from './MainContainer.module.css';
import Settings from '../Settings';
import RecentApps from '../../components/RecentApps/RecentApps';

const MainContainer = ({ title, setActive }) => {
    const history = useHistory();
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const logo = lodashGet(state, 'user.properties.logo');
    const userRole = lodashGet(state, 'user.userRole');
    const accesableRoutes = lodashGet(state, 'user.allowed');
    const passwordExpired = lodashGet(state, 'user.passwordUpdatedOn') + 7890000 < moment().utc().unix();
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const savedUsers = getUsers();
    const userId = lodashGet(state, 'user._id');
    const curUser = savedUsers.find((u) => u.id === userId && u.userRole === userRole);
    const canSwitchToUsers = savedUsers.filter((u) => u.id !== userId || u.userRole !== userRole);

    const [openSettings, setOpenSettings] = useState(false);

    useEffect(() => {
        if (passwordExpired) setOpenUpdatePassword(true);
    }, [passwordExpired]);

    const initialPasswordValues = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleUpdatePassword = (values) => {
        const payload = {
            currentPassword: values?.currentPassword,
            newPassword: values?.newPassword,
            confirmNewPassword: values?.confirmNewPassword,
        };
        UserService.updatePasswordInfo(
            payload,
            () => startLoader('updatePassword'),
            handlePasswordUpdateSuccess,
            handlePasswordUpdateError,
            () => stopLoader('updatePassword')
        );
    };

    const handlePasswordUpdateSuccess = () => {
        toast.success('Password updated successfully!');
        setOpenUpdatePassword(false);
    };

    const handlePasswordUpdateError = (error) => {
        let message = error?.response?.data?.message;
        toast.error(message);
    };

    const handleLogout = () => {
        UserService.logout(
            () => startLoader('logout'),
            handleSuccess,
            handleError,
            () => stopLoader('logout')
        );
    };

    const handleSuccess = ({ data }) => {
        toast.success('Logout Success!');
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const handleError = (e) => {
        authDispatch({ type: REMOVE_USER });
        localStorage.clear();
        history.push('/auth/login');
    };

    const rootUserRoutes = () => {
        return PROTECTED_ROUTES_ROOT;
        // if (userRole === "root") {
        //   return PROTECTED_ROUTES_ROOT;
        // } else if (userRole == "root-admin" || userRole == "root-developer") {
        //   const routes = [];
        //   accesableRoutes?.properties?.[userRole]?.map((item) => {
        //     PROTECTED_ROUTES_ROOT.map((route) => {
        //       if (route.id === item.componentId) {
        //         routes.push(route);
        //       }
        //     });
        //   });
        //   return routes;
        // } else {
        //   return [];
        // }
    };

    let processedFilterRoutes =
        userRole === 'root' || userRole === 'root-admin' || userRole === 'root-developer'
            ? rootUserRoutes()
            : userRole === 'admin' || userRole === 'sub-admin'
                ? PROTECTED_ROUTES
                : userRole === 'enabler'
                    ? PROTECTED_ROUTES_ENABLER
                    : [];

    let processedRoutes = processedFilterRoutes
        .map((route) => {
            return route.childRoutes ? (
                route.childRoutes.map((childRoute) => <Route exact path={`${route.url}${childRoute.url}`} component={childRoute.component} />)
            ) : (
                <Route exact path={route.url} component={route.component} />
            );
        })
        .flat();
    processedRoutes?.push(<Route path={`*`} component={NotFound} />);

    const getProfileDetails = () => {
        return (
            <div className={classes.ProfilePreview}>
                {logo ? (
                    <div className={classes.CompanyLogoWrapper}>
                        <img src={logo} alt="logo" className={classes.CompanyLogo} />
                    </div>
                ) : (
                    <div className={classes.CompanyLogoWrapper}>
                        <img src={SCLogo} className={classes.CompanyLogo} />
                    </div>
                )}
                <div className={classes.ProfileImageWrapper}>
                    <img src={state?.user?.profileImage || profile} alt="dp" className={classes.ProfileImage} />
                </div>
            </div>
        );
    };

    const handleSwitchUser = (user) => {
        if (user.token) {
            localStorage.setItem('GM_TOKEN', user.token);
            window.location.reload();
        }
    };

    const goToLogin = () => {
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const logoutAll = () => {
        authDispatch({ type: REMOVE_USER });
        removeAllUsers();
        history.push('/auth/login');
    };

    return (
        <div>
            <div className={classes.NavBar}>
                <div className={classes.NavBarStart}>
                    <div className={classes.Title}>{title}</div>
                </div>
                <div className={classes.NavBarEnd}>
                    <Clock />
                    <Menu menuButton={getProfileDetails()} align="end" arrow={true} menuClassName={classes.ProfileMenu}>
                        <MenuItem className={classes.RoleName}>
                            <div className={classes.UserName}>Hey, {state?.user?.name}</div>
                            <div className={classes.UserRole}>User Role: {state?.user?.userRole}</div>
                        </MenuItem>
                        <MenuDivider />
                        {(curUser?.userRole === 'admin' || curUser?.userRole === 'sub-admin') && (
                            <MenuItem className={classes.ProfileMenuItems} onClick={() => setOpenSettings(true)}>
                                Settings
                            </MenuItem>
                        )}
                        <MenuItem className={classes.ProfileMenuItems} onClick={() => setOpenUpdatePassword(true)}>
                            Update Password
                        </MenuItem>
                        <SubMenu label="Switch User" className={classes.ProfileMenuItems}>
                            <MenuItem className={classes.MenuItemClassNameSW2}>
                                <div className={classes.CurUserImageWrapper}>
                                    <img src={curUser?.profileImage} className={classes.CurUserImage} />
                                </div>
                                <div>
                                    <div className={classes.UserCustomer}>User Role: {curUser?.userRole}</div>
                                    <div className={classes.UserEmail}>{curUser?.email}</div>
                                    <div className={classes.CurrentAccount}>Current</div>
                                </div>
                            </MenuItem>
                            <MenuDivider className={classes.MenuDivider} />
                            <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                {canSwitchToUsers.length ? (
                                    <>
                                        {canSwitchToUsers.map((u) => (
                                            <div className={classes.TimeAndSource}>
                                                <MenuItem className={classes.MenuItemClassNameSW} onClick={() => handleSwitchUser(u)}>
                                                    <div className={classes.CurUImageWrapper}>
                                                        <div className={classes.CurUImageCont}>
                                                            <img src={u?.profileImage} className={classes.CurUserImage} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={classes.UserCustomer}>User Role: {u?.userRole}</div>
                                                        <div className={classes.UserEmail}>{u?.email}</div>
                                                    </div>
                                                </MenuItem>
                                                <div
                                                    onClick={() => {
                                                        removeUser(u?.id, u?.userRole);
                                                        window.location.reload();
                                                    }}
                                                    style={{
                                                        paddingRight: '1vw',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <CustomTooltip
                                                        content={'Remove Account'}
                                                    >
                                                        <UilTimesCircle size={'1.2vw'} style={{
                                                            color: 'var(--color-primary)'
                                                        }} />
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        ))}
                                        <MenuDivider className={classes.MenuDivider} />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <MenuItem className={classes.MenuItemClassNameSW} onClick={goToLogin}>
                                <div className={classes.CurUImageWrapper}>
                                    <div className={classes.CurUImageCont}>
                                        <img src={AddUserIcon} className={classes.CurUserImage} />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.UserCustomer}>Add Account</div>
                                </div>
                            </MenuItem>
                            <MenuItem className={classes.MenuItemClassNameSW} onClick={logoutAll}>
                                <div className={classes.CurUImageWrapper}>
                                    <div className={classes.CurUImageCont}>
                                        <img src={LogoutAllIcon} className={classes.CurUserImage} />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.UserCustomer}>Logout All Accounts</div>
                                </div>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </div>
            </div>

            <div className={classes.DashBoard}>
                <Switch>{processedRoutes}</Switch>
            </div>
            <ModalComponent
                isOpen={openUpdatePassword}
                setOpen={setOpenUpdatePassword}
                onClose={() => {
                    !passwordExpired && setOpenUpdatePassword(false);
                }}
            >
                <Formik initialValues={initialPasswordValues} validationSchema={CustomerPasswordValidation} onSubmit={(val, { resetForm }) => handleUpdatePassword(val, resetForm)}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <Typography content="Update Password" />
                                    {passwordExpired && <div className="error-msg">You need to change your password due to expiration.</div>}
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="currentPassword">Current Password</label>
                                                <Password name="currentPassword" id="currentPassword" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="newPassword">New Password</label>
                                                <Password name="newPassword" id="newPassword" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="confirmNewPassword">Confirm New Password</label>
                                                <Password name="confirmNewPassword" id="confirmNewPassword" />
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                            <div>
                                                {passwordExpired ? (
                                                    <div className="btn-secondary" onClick={handleLogout}>
                                                        Logout
                                                    </div>
                                                ) : (
                                                    <div className="btn-secondary" onClick={() => setOpenUpdatePassword(false)}>
                                                        Cancel
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalComponent>

            <ModalComponent isOpen={openSettings} setOpen={setOpenSettings}>
                <div style={{ width: '50vw' }}>
                    <Settings setOpen={setOpenSettings} />
                </div>
            </ModalComponent>
            <RecentApps setActive={setActive} />
        </div>
    );
};

export default MainContainer;
