// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    read: '/root/eic/redis-data',
    logs: '/root/eic/logs',
    powerLogs: '/root/eic/power-logs',
    updateRedisValue: '/root/eic/redis-value',
    eicCodes: '/root/eic/eic-codes',
    eicLogs: '/root/eic/eic-logs',
    eicSignalLogs: '/root/eic/eic-signal-logs',
};

const GetRedisData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read, { params }).then(callback).catch(error).finally(next);
};
const GetLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.logs, { params }).then(callback).catch(error).finally(next);
};

const GetPowerLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.powerLogs, { params }).then(callback).catch(error).finally(next);
};

const UpdateRedisValue = (payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateRedisValue}`, payload).then(callback).catch(error).finally(next);
};

const GetEicCodes = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.eicCodes).then(callback).catch(error).finally(next);
};

const GetEicLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.eicLogs, { params }).then(callback).catch(error).finally(next);
};

const GetEicSignalLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.eicSignalLogs, { params }).then(callback).catch(error).finally(next);
};

export const RootAfrrService = {
    GetRedisData,
    GetLogs,
    GetPowerLogs,
    UpdateRedisValue,
    GetEicCodes,
    GetEicLogs,
    GetEicSignalLogs,
};
