// Standard library imports
import React, { useEffect, useState } from 'react';

// Extenal library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilImport } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal module imports
import { EdgeService } from '../../../services/EdgeService';
import { useLoader } from '../../../hooks';
import { Input, ToggleButtonWithState } from '../../../components/Inputs/Input';
import { EdgeControlTest } from '../../../validations/Edge/ControlTestValidation';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnlyWithms } from '../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import { signalsService } from '../../../services/signalsService';
import { Capitalize } from '../../../utils/stringHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { flatten } from 'flat';

// Css imports
import classes from '../../../styles/CreateDevice.module.css';
import edgeClasses from '../Edge.module.css';

const Control = ({
    edgeData,
    setScheduleModal
}) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();

    const [powerData, setPowerData] = useState([]);
    const [setPointData, setSetPointData] = useState([]);
    const [downloadable, setDownloadable] = useState([]);

    const [lastStatus, setLastStatus] = useState('--');
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [counterInterval, setCounterInterval] = useState(null);

    const [date, setDate] = useState(new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [showGraph, setShowGraph] = useState(false);

    const [lastRequest, setLastRequest] = useState({
        status: false,
        value: 0
    });

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);


    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm:ss');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    const autoRefreshLastReportedData = (signalId) => {
        if (signalId) {
            signalsService.autoRefreshLastReportedData({ signalId: signalId }, handleLastReportedSuccess, handleError);
        }
    };

    const autoRefeshData = () => {
        if (edgeData.signalId) {
            autoRefreshLastReportedData(edgeData.signalId);
            setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'));
            const params = {
                start: startTime,
                end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'),
                date: moment(date).format('YYYY-MM-DD'),
                signalId: edgeData.signalId,
                prefillData: false,
                dataDecimation: true,
            };
            signalsService.AutoRefreshDataTest(params, handleSignalDataSucess, handleError);
        }
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    }

    const handleSubmit = (values) => {
        let profiles = [values];
        const payload = {
            profiles: profiles,
            edgeId: edgeData.deviceId,
        };
        EdgeService.SendControlData(
            payload,
            startLoader,
            (res) => {
                const response = res?.data?.data?.status;
                if (response === 'NO RESPONSE FROM EDGE') {
                    if (!lastRequest.status) {
                        setLastRequest({
                            status: false,
                            value: values.setPoint
                        });
                    }
                } else {
                    setLastRequest({
                        status: true,
                        value: values.setPoint
                    });
                }
                handleSendControlSuccess(res);
            },
            (error) => {
                if (!lastRequest.status) {
                    setLastRequest({
                        status: false,
                        value: values.setPoint
                    });
                }
                handleError(error)
            },
            stopLoader
        );
    };

    const readSignal = (params, successHandler) => {
        signalsService.ReadSignalDataFromSourceTest(
            params,
            () => startLoader('read-data'),
            successHandler,
            handleError,
            () => stopLoader('read-data')
        );
    };

    const readSignalData = () => {
        if (edgeData?.signalId) {
            let params = {
                start: startTime,
                end: endTime,
                date: moment(date).format('YYYY-MM-DD'),
                signalId: edgeData.signalId,
                prefillData: false,
                dataDecimation: true,
            };
            readSignal(params, handleSignalDataSucess);
            getLastReportedData(edgeData.signalId);
        }
    };

    const getLastReportedData = (signalId) => {
        if (signalId) {
            signalsService.getLastReportedData({ signalId: signalId }, startLoader, handleLastReportedSuccess, handleError, stopLoader);
        }
    };

    const handleLastReportedSuccess = ({ data }) => {
        if (data.data) {
            setLastStatus(moment(data?.data).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setLastStatus('--');
        }
    };

    const handleSignalDataSucess = ({ data }) => {
        setDownloadable(data.data?.downloadable)
        setPowerData(data.data?.power);

        let isSetPointData = false;
        const setPointDatas = data.data?.setPoint;
        setPointDatas?.[0]?.data?.forEach((setPoint) => {
            if (setPoint.y !== null) isSetPointData = true;
        });

        if (isSetPointData) {
            setSetPointData(setPointDatas);
        } else {
            setSetPointData([]);
        }
    };

    const handleSendControlSuccess = ({ data }) => {
        const response = data?.data?.status;
        if (response === 'NO RESPONSE FROM EDGE') {
            toast.error(response);
        } else {
            toast.success(response);
        }
        setShowGraph(true);
        readSignalData();
        setIsAutorefresh(true);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleDownload = () => {
        const downloadData = downloadable;
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadData
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }

        flattenedData?.length > 0 && DownloadAsExcel(flattenedData, `signal-${Capitalize(edgeData.signalId)}`, columnNames, columnIndex);
    };

    return (
        <div style={{ width: '45vw', maxHeight: '85vh', overflow: 'auto' }}>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        setPoint: 0
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={EdgeControlTest}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={edgeClasses.ScheduleTestInputContainer} style={{ alignItems: 'end' }}>
                                    <div className={edgeClasses.ControlTestInputContainer}>
                                        <div className={classes.FieldControl3}>
                                            <label for="setPoint">
                                                Load ( % )
                                                <span className="required">*</span>
                                            </label>
                                            <div>
                                                <Input type='number' name="setPoint" id="setPoint" />
                                            </div>
                                        </div>
                                        <div>

                                            <button type="submit" className="btn-primary">
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" className="btn-secondary" onClick={() => {
                                            if (lastRequest.value === 0) {
                                                setScheduleModal({ status: false, data: '' });
                                                setIsAutorefresh(false);
                                            } else {
                                                toast.warn('To close modal, send a command with value 0.')
                                            }
                                        }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            {showGraph &&
                (<div className={classes.SignalViewerClasses}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '0.5vw',
                            marginTop: '2vw'
                        }}
                    >
                        <div style={{ display: 'flex', gap: '0.5vw', }}>
                            <ToggleButtonWithState
                                label={'Auto Refresh'}
                                value={isAutoRefresh}
                                style={{ marginTop: '0' }}
                                onChange={(e) => {
                                    setIsAutorefresh(e.target.checked);
                                    clearInterval(counterInterval);
                                    setSeconds(30);
                                }}
                            />
                            {isAutoRefresh && (
                                <label
                                    style={{
                                        fontSize: '0.72vw',
                                        fontWeight: '500',
                                        marginLeft: '-4px',
                                    }}
                                >
                                    in {seconds} seconds
                                </label>
                            )}
                        </div>
                        <div onClick={() => handleDownload()}>
                            <UilImport
                                size="1.5vw"
                                style={{
                                    cursor: 'pointer',
                                    margin: '0.4vw 0.4vw 0 0',
                                    color: 'var(--color-primary)',
                                }}
                            />
                        </div>
                    </div>

                    {
                        setPointData.length > 0 && (<ChartComponent
                            height="80%"
                            series={setPointData}
                            title={'Set Point'}
                            type="line"
                            xFormatter={timeOnlyWithms}
                            lastReported={lastStatus}
                            yFormatter={formatYAxisValue}
                            chartProps={{ group: 'setPoint' }}
                        />)
                    }

                    <ChartComponent
                        height="80%"
                        series={powerData}
                        title={'Power'}
                        type="line"
                        xFormatter={timeOnlyWithms}
                        lastReported={lastStatus}
                        yFormatter={formatYAxisValue}
                        chartProps={{ group: 'power' }}
                    />
                </div>)
            }
        </div>
    );
};

export default Control;
