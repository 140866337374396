// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import moment from 'moment';
import { UilBellSlash, UilInfoCircle } from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import { toast } from 'react-toastify';
import _ from 'lodash';
// Internal module imports
import { DropdownComponent, ToggleButtonWithState } from '../../../components/Inputs/Input';
import dashboardClasses from './index.module.css';
import classes from '../../../styles/AllDevices.module.css';
import { EdgeService } from '../../../services/EdgeService';
import { useLoader } from '../../../hooks';
import { SNOOZE_MINUTES } from '../../../constants';
import EdgeClasses from '../Edge.module.css';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { SnoozeService } from '../../../services/snoozeService';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnly, timeOnlyWithms } from '../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import { CustomerService } from '../../../services/CustomerService';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import ForceFactorActivation from '../ModalComponent/ForceFactorActivationModal';
import BidInfoModal from '../ModalComponent/BidInfoModal';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import TabsComponent from '../../../components/Tabs/Tabs';
import { activationProfileService } from '../../../services/ActivationProfileService';

const Dashboard = ({ data, updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([]);
    const [edge, setEdge] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(queryParamsData?.customer ? JSON.parse(queryParamsData.customer) : data.selectedCustomer);
    const [selectedEdge, setSelectedEdge] = useState(
        queryParamsData?.edge
            ? queryParamsData.edge
            : {
                label: '',
                value: data.edgeId,
                _id: data?._id,
                profile: data?.profile || {},
                frequency: data?.frequency || {},
            }
    );
    const [isParams, setParams] = useState(queryParamsData?.edge || data?.edgeId ? true : false);
    const [thirtySec, setThirtySec] = useState(30);
    const [oneMin, setOneMin] = useState(60);

    const [notificationStates, setNotificationStates] = useState({
        master: false,
        heartbeat: false,
        instanceReporting: false,
        signalReporting: false,
        apiCalls: false,
        constantReporting: false,
        intMetaHeartbeat: false,
        error: false,
        notification: false,
        teamViewer: false
    });

    const [edgeData, setEdgeData] = useState(null);
    const [reporting, setReporting] = useState({});
    const [instanceGraphData, setInstanceGraphData] = useState({
        instanceName: '',
        data: [],
        consumptionData: [],
    });
    const [frequencyGraphData, setFrequencyGraphData] = useState({
        signalName: '',
        frequency: [],
        power: [],
    });

    const [resourceStats, setResourceStats] = useState({
        cpu: '',
        ram: '',
    });
    const [bidStatus, setBidStatus] = useState();

    const [thirtySecIntervals, setThirtySecInstervals] = useState(null);
    const [oneMinIntervals, setOneMinInstervals] = useState(null);
    const [forceFactorActivationoStatus, setForceFactorActivationStatus] = useState({
        activationStatus: '--',
        factorStatus: '--',
    });
    const [bidsModal, setBidsModal] = useState(false);
    const [forceModal, setForceModal] = useState(false);
    const [instance, setInstance] = useState({});
    const [bidHourlyData, setBidHourlyData] = useState([]);
    const viewOptions = [
        { label: 'Live', value: 'live' },
        { label: 'Detailed', value: 'detailed' },
    ];
    const [selectedView, setSelectedView] = useState(queryParamsData?.view ? queryParamsData.view : viewOptions[0]);

    const curCetDateTime = momentTimeFormater();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date());
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.add(1, 'minute').format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(30, 'minutes').format('HH:mm:ss'));

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                customerCode: element.customerCode,
                license: element.license,
                lowVisible: element.properties.lowVisible,
                highVisible: element.properties.highVisible,
            });
        });
        setCustomers(temp);
    };

    useEffect(() => {
        if (selectedCustomer?.value) {
            getEdge();
        }
    }, [selectedCustomer]);

    useEffect(() => {
        if (selectedEdge?.value) {
            getReportingData();
            getResourceStatsData();
            getEdgeData();
            getBidStatus();
        }
    }, [selectedEdge]);

    useEffect(() => {
        if (edgeData) {
            getInstanceGraphData();
            getFrequencyGraphData();
        }
    }, [edgeData]);

    useEffect(() => {
        if (selectedCustomer?.value && instance._id && edgeData?.market) {
            getBidHourlyPrice();
        }
    }, [selectedCustomer?.value, instance?._id, edgeData?.market]);

    const getEdge = () => {
        EdgeService.GetEdgeByCustomerId(selectedCustomer.value, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const handleGetDataSuccess = ({ data }) => {
        let temp = data.data.map((e) => ({
            label: e.edgeAlias,
            value: e.deviceId,
            _id: e._id,
            profile: e?.properties?.profile || {},
            frequency: e.properties?.frequency || {},
        }));
        setEdge(temp);
        if (selectedEdge?.value !== temp?.[0].value) {
            if (!isParams) {
                setSelectedEdge(temp[0]);
            }
        }
        setParams(false);
    };

    const handleError = (err) => {
        if (err && err.response) {
            toast.error(err.response.data.message);
            if (err.response.data.message === 'Signal not configure.') {
                setFrequencyGraphData({
                    signalName: '',
                    frequency: [],
                    power: [],
                });
            }
            if (err.response.data.message === 'Instance not configure.') {
                setInstanceGraphData({
                    instanceName: '',
                    data: [],
                    consumptionData: [],
                });
            }
        }
    };

    const handleToggleChange = (e, type) => {
        setNotificationStates({
            ...notificationStates,
            [type]: e.target.checked,
        });
        updateNotificationStates(e.target.checked, type);
    };

    const updateNotificationStates = (value, type) => {
        const payload = {
            value,
            type,
            edgeDeviceId: selectedEdge.value,
        };
        EdgeService.UpdateNotificationStates(payload, startLoader, handleUpdateNotificationSuccess, handleError, stopLoader);
    };

    const handleUpdateNotificationSuccess = ({ data }) => {
        toast.success('Updated!');
    };

    const getEdgeData = () => {
        EdgeService.GetEdgeByDeviceId(selectedEdge.value, startLoader, handleGetEdgeDataSuccess, handleError, stopLoader);
    };

    const handleGetEdgeDataSuccess = ({ data }) => {
        setEdgeData(data.data);
        setNotificationStates(data.data?.properties?.notificationConfig);
        const instanceData = data.data?.instanceData?.[0];
        const market = data.data?.market;
        let forceAcivationStatus = '';
        let forceFactorStatus = '';
        if (instanceData?.forceAcivationStatus && market) {
            forceAcivationStatus = instanceData.forceAcivationStatus[market];
        }
        if (instanceData?.forceFactorStatus && market) {
            forceFactorStatus = instanceData.forceFactorStatus[market]?.status;
        }
        setForceFactorActivationStatus({
            activationStatus: forceAcivationStatus ? forceAcivationStatus : '--',
            factorStatus: forceFactorStatus ? forceFactorStatus : '--',
        });
        setInstance(instanceData ? instanceData : {});
    };

    const getBidStatus = () => {
        if (selectedEdge?.value) {
            console.log('selectedCustomer', selectedCustomer);
            EdgeService.GetBidStatus({ edgeId: selectedEdge.value, customerId: selectedCustomer.value }, startLoader, handleGetBidStatusSuccess, handleError, stopLoader);
        }
    };

    const handleGetBidStatusSuccess = ({ data }) => {
        setBidStatus(data?.data?.status);
    };

    const getBidHourlyPrice = () => {
        EdgeService.GetBidHourlyPrice(
            {
                customerId: selectedCustomer.value,
                instanceId: instance._id,
                market: edgeData.market,
            },
            startLoader,
            handleGetHourlyPriceSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetHourlyPriceSuccess = ({ data }) => {
        setBidHourlyData(_.get(data, ['data', 'data', 'economics', 'hourlyData'], []));
    };

    const getReportingData = (showLoader = true) => {
        if (selectedEdge?.value) {
            EdgeService.GetReportingData(selectedEdge.value, showLoader ? startLoader : () => { }, handleReportingDataSuccess, handleError, showLoader ? stopLoader : () => { });
        }
    };

    const handleReportingDataSuccess = ({ data }) => {
        setReporting({
            health: data.data.health,
            instanceReporting: data.data.instanceReporting,
            signalReporting: data.data.signalReporting,
            apiCalls: data.data.apiCalls,
        });
    };

    const snooze = (id, value, type, resource, resourceName) => {
        const payload = {
            resourceId: id,
            type: type,
            snoozeForMinutes: value.value,
            resource,
            resourceName,
        };
        SnoozeService.Create(payload, startLoader, () => handleSnoozeSuccess(value.label), handleError, stopLoader);
    };

    const handleSnoozeSuccess = (value) => {
        getEdgeData();
        if (value === 'Cancel Snooze') {
            toast.success(`Snooze Canceled.`);
        } else {
            toast.success(`Snooze for ${value}.`);
        }
    };

    const isSnooze = (data = [], type) => {
        const typeSnoozeStat = data.find((item) => item.type === type);
        if (typeSnoozeStat) {
            const snoozedTime = moment(typeSnoozeStat.timestamp).add(typeSnoozeStat.snoozeForMinutes, 'minutes');
            const isSnoozed = snoozedTime.isAfter(moment().utc());
            if (isSnoozed) {
                return `Snoozed Till (CET): ${momentTimeFormater(snoozedTime).format('YYYY-MM-DD-HH:mm:ss')}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const getInstanceGraphData = (showLoader = true, cdate, cstartTime, cendTime) => {
        if (edgeData?.groupId) {
            const curCetDateTime = momentTimeFormater();
            const params = {
                date: momentTimeFormater(cdate ? cdate : selectedView?.value === 'live' ? curCetDateTime : date).format('YYYY-MM-DD'),
                endTime: cendTime ? cendTime : selectedView?.value === 'live' ? curCetDateTime.add(1, 'minute').format('HH:mm') : endTime,
                startTime: cstartTime ? cstartTime : selectedView?.value === 'live' ? curCetDateTime.subtract(30, 'minutes').format('HH:mm') : startTime,
            };
            EdgeService.GetInstanceGraphData(
                edgeData.groupId,
                params,
                showLoader ? startLoader : () => { },
                handleGetInstaceDataDataSuccess,
                handleError,
                showLoader ? stopLoader : () => { }
            );
        }
    };

    const handleGetInstaceDataDataSuccess = ({ data }) => {
        setInstanceGraphData({
            instanceName: data.data?.instance?.name || '',
            data: data.data.powerData || [],
            consumptionData: data.data.consumptionData || [],
        });
    };

    const getFrequencyGraphData = (showLoader = true, cdate, cstartTime, cendTime) => {
        if (edgeData?.properties?.signalId) {
            const curCetDateTime = momentTimeFormater();
            const params = {
                date: momentTimeFormater(cdate ? cdate : selectedView?.value === 'live' ? curCetDateTime : date).format('YYYY-MM-DD'),
                endTime: cendTime ? cendTime : selectedView?.value === 'live' ? curCetDateTime.add(1, 'minute').format('HH:mm') : endTime,
                startTime: cstartTime ? cstartTime : selectedView?.value === 'live' ? curCetDateTime.subtract(30, 'minutes').format('HH:mm') : startTime,
            };
            EdgeService.GetFrequencyGraphData(
                edgeData.properties.signalId,
                params,
                showLoader ? startLoader : () => { },
                handleGetFrequencyDataSuccess,
                handleError,
                showLoader ? stopLoader : () => { }
            );
        }
    };

    const handleGetFrequencyDataSuccess = ({ data }) => {
        setFrequencyGraphData({
            signalName: data.data.frequency[0]?.name || '',
            frequency: data.data.frequency,
            power: data.data.power,
        });
    };

    const getResourceStatsData = (showLoader = true) => {
        if (selectedEdge?.value) {
            EdgeService.GetResourceStatsData(
                selectedEdge.value,
                showLoader ? startLoader : () => { },
                handleGetResourceStatsDataSuccess,
                handleError,
                showLoader ? stopLoader : () => { }
            );
        }
    };

    const handleGetResourceStatsDataSuccess = ({ data }) => {
        setResourceStats({
            cpu: data.data?.cpuUsagePercentage || '',
            ram: data.data?.memoryUsagePercentage || '',
        });
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    useEffect(() => {
        if (selectedView.value === 'live') {
            const interval = setInterval(() => {
                if (thirtySec > 0) {
                    setThirtySec(thirtySec - 1);
                } else {
                    if (edgeData?.groupId) {
                        getFrequencyGraphData(false);
                        getResourceStatsData(false);
                    }
                    setThirtySec(30);
                }
            }, 1000);
            setThirtySecInstervals(interval);
            return () => clearInterval(interval);
        }
    }, [thirtySec, edgeData, selectedView]);

    useEffect(() => {
        if (selectedView.value === 'live') {
            const interval = setInterval(() => {
                if (oneMin > 0) {
                    setOneMin(oneMin - 1);
                } else {
                    if (edgeData?.groupId) {
                        getInstanceGraphData(false);
                        getReportingData(false);
                    }
                    setOneMin(60);
                }
            }, 1000);
            setOneMinInstervals(interval);
            return () => clearInterval(interval);
        }
    }, [oneMin, edgeData, selectedView]);

    useEffect(() => {
        clearInterval(thirtySecIntervals);
        clearInterval(oneMinIntervals);
        setThirtySec(30);
        setOneMin(60);
    }, [selectedCustomer, selectedEdge]);

    useEffect(() => {
        if (selectedView?.value == 'detailed') {
            clearInterval(thirtySecIntervals);
            clearInterval(oneMinIntervals);
            setThirtySec(30);
            setOneMin(60);
        }
    }, [selectedView]);

    const handleSubmit = () => {
        getInstanceGraphData(true, date, startTime, endTime);
        getFrequencyGraphData(true, date, startTime, endTime);
    };

    return (
        <div>
            <div className={dashboardClasses.Filter}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div className={dashboardClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label className={dashboardClasses.Label}>Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    defaultValue={selectedCustomer}
                                    onChange={(e) => {
                                        if (e.value !== selectedCustomer?.value) {
                                            setSelectedCustomer(e);
                                        }
                                        updateQueryParams('customer', e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label className={dashboardClasses.Label}>Edge</label>
                                <DropdownComponent
                                    name="edgeId"
                                    options={edge}
                                    defaultValue={selectedEdge}
                                    onChange={(e) => {
                                        if (e.value !== selectedEdge?.value) {
                                            setSelectedEdge(e);
                                        }
                                        updateQueryParams('edge', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>

                        <div>
                            <div className={classes.FieldControl}>
                                <label className={dashboardClasses.Label}>View</label>
                                <DropdownComponent
                                    options={viewOptions}
                                    defaultValue={selectedView}
                                    onChange={(e) => {
                                        if (e.value !== selectedView?.value) {
                                            setSelectedView(e);
                                        }
                                        updateQueryParams('view', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        {selectedView.value === 'detailed' && (
                            <div className={dashboardClasses.FilterContainer} style={{ alignItems: 'center' }}>
                                <div className={classes.Label}>
                                    <label className={dashboardClasses.Label}>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>
                                <div className={classes.Label}>
                                    <label className={dashboardClasses.Label}>Start Time</label>
                                    <CustomTimePicker
                                        startTime={startTime}
                                        setStartTime={(e) => {
                                            setStartTime(e);
                                            updateQueryParams('startTime', e);
                                        }}
                                        type="start"
                                        maxDetail={'second'}
                                    />
                                </div>

                                <div className={classes.Label}>
                                    <label className={dashboardClasses.Label}>End Time</label>
                                    <CustomTimePicker
                                        endTime={endTime}
                                        setEndTime={(e) => {
                                            setEndTime(e);
                                            updateQueryParams('endTime', e);
                                        }}
                                        type="end"
                                        maxDetail={'second'}
                                    />
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="btn-primary"
                                        style={{
                                            width: 'auto',
                                            marginTop: '1.5vw',
                                        }}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className={classes.AllDevices}>
                {/* card conatainer */}
                <div className={dashboardClasses.CardContainer}>
                    <div
                        className={dashboardClasses.Card}
                        style={reporting.health == 'OK' ? { background: '#C1F2B0', color: '#65B741' } : { background: '#FFAFAF', color: '#D80032' }}
                    >
                        <p>Health</p>
                        <div>{reporting.health}</div>
                    </div>
                    <div
                        className={dashboardClasses.Card}
                        style={reporting.instanceReporting == 'Reporting' ? { background: '#C1F2B0', color: '#65B741' } : { background: '#FFAFAF', color: '#D80032' }}
                    >
                        <p>Instance</p>
                        <div>{reporting.instanceReporting}</div>
                    </div>
                    <div
                        className={dashboardClasses.Card}
                        style={reporting.signalReporting == 'Reporting' ? { background: '#C1F2B0', color: '#65B741' } : { background: '#FFAFAF', color: '#D80032' }}
                    >
                        <p>Signal</p>
                        <div>{reporting.signalReporting}</div>
                    </div>
                    <div
                        className={dashboardClasses.Card}
                        style={reporting.apiCalls == 'OK' ? { background: '#C1F2B0', color: '#65B741' } : { background: '#FFAFAF', color: '#D80032' }}
                    >
                        <p>API Calls</p>
                        <div>{reporting.apiCalls}</div>
                    </div>
                    <div className={dashboardClasses.Card} style={{ background: '#C1F2B0', color: '#65B741' }}>
                        <p>CPU Stats</p>
                        <div>{resourceStats.cpu ? `${resourceStats.cpu}%` : '--'}</div>
                    </div>
                    <div className={dashboardClasses.Card} style={{ background: '#C1F2B0', color: '#65B741' }}>
                        <p>RAM Stats</p>
                        <div>{resourceStats.ram ? `${resourceStats.ram}%` : '--'}</div>
                    </div>
                    <div className={dashboardClasses.Card} style={bidStatus == 1 ? { background: '#C1F2B0', color: '#65B741' } : { background: '#FFAFAF', color: '#D80032' }}>
                        <p>
                            Bid Status
                            {bidStatus == 1 && <UilInfoCircle className={dashboardClasses['Info-icon']} size={'0.9vw'} onClick={() => setBidsModal(true)} />}
                        </p>
                        <div>{bidStatus || '--'}</div>
                    </div>
                    <div
                        className={dashboardClasses.Card}
                        style={{
                            background: '#C1F2B0',
                            color: '#65B741',
                            cursor: 'pointer',
                        }}
                        onClick={() => setForceModal(true)}
                    >
                        <p>Force Activation/Factor</p>
                        <div>
                            {forceFactorActivationoStatus.activationStatus}/{forceFactorActivationoStatus.factorStatus}
                        </div>
                    </div>
                </div>

                {/* chart card */}
                <div style={{ marginTop: '2vh' }}>
                    <div class={dashboardClasses.ChartCardContainer}>
                        <div class={dashboardClasses.ChartCard}>
                            <ChartComponent
                                series={frequencyGraphData.frequency}
                                title={`Last 30 min frequency data - ${frequencyGraphData.signalName} `}
                                xFormatter={timeOnly}
                                lastReported={selectedView.value === 'live' && `Refresh in ${thirtySec} seconds`}
                                showLastReported={false}
                                yProps={{
                                    min: selectedCustomer?.lowVisible && selectedCustomer?.lowVisible,
                                    max: selectedCustomer?.highVisible && selectedCustomer?.highVisible,
                                    tickAmount: 4,
                                }}
                                height="80%"
                                type="line"
                            />
                        </div>
                        <div class={dashboardClasses.ChartCard}>
                            <ChartComponent
                                series={frequencyGraphData.power}
                                title={`Last 30 min power data - ${frequencyGraphData.signalName} `}
                                xFormatter={timeOnly}
                                yFormatter={formatYAxisValue}
                                lastReported={selectedView.value === 'live' && `Refresh in ${thirtySec} seconds`}
                                showLastReported={false}
                                height="80%"
                            />
                        </div>
                        <div class={dashboardClasses.NotificationCard} style={{ overflowY: 'scroll' }}>
                            <p>Notification Control</p>
                            <div>
                                <ToggleButtonWithState
                                    label={`Master`}
                                    value={notificationStates?.master}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'master');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.heartbeat}
                                    label={`Heartbeat`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'heartbeat');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.instanceReporting}
                                    label={`Instance Reporting`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'instanceReporting');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.signalReporting}
                                    label={`Signal Reporting`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'signalReporting');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.constantReporting}
                                    label={`Constant Reporting`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'constantReporting');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.apiCalls}
                                    label={`API Calls`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'apiCalls');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.constantFrequency}
                                    label={`Constant Frequecy`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'constantFrequency');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.error}
                                    label={`Error`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'error');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.notification}
                                    label={`Notification`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'notification');
                                    }}
                                />
                            </div>
                            <div>
                                <ToggleButtonWithState
                                    value={notificationStates?.teamViewer}
                                    label={`Team Viewer`}
                                    labelPosition={'left'}
                                    style={{ justifyContent: 'space-between' }}
                                    onChange={(e) => {
                                        handleToggleChange(e, 'teamViewer');
                                    }}
                                />
                            </div>
                            <div className={dashboardClasses.SnoozeContainer}>
                                <label>Snooze</label>
                                <div>
                                    <Menu
                                        menuButton={
                                            <div>
                                                <UilBellSlash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        }
                                        direction={'left'}
                                        arrow={true}
                                        menuClassName={EdgeClasses.Menu}
                                    >
                                        <MenuItem>
                                            <div className={EdgeClasses.SnoozeHeader}>Snooze</div>
                                        </MenuItem>
                                        <MenuDivider />
                                        <SubMenu
                                            label={
                                                <div style={{ textAlign: 'left' }}>
                                                    <span style={{ fontSize: '0.8vw', fontWeight: 500 }}>Data Monitoring</span>
                                                    <br />
                                                    <span style={{ color: '#faa500' }}>
                                                        {isSnooze(edgeData?.snoozeData, 'dataMonitoring') ? isSnooze(edgeData?.snoozeData, 'dataMonitoring') : ''}
                                                    </span>
                                                </div>
                                            }
                                        >
                                            {SNOOZE_MINUTES.map((option, index) => (
                                                <div>
                                                    <MenuItem onClick={() => snooze(edgeData._id, option, 'dataMonitoring', 'edge', edgeData.edgeAlias)}>
                                                        <div>{option.label}</div>
                                                    </MenuItem>
                                                    {index < SNOOZE_MINUTES.length - 1 && <MenuDivider />}
                                                </div>
                                            ))}
                                        </SubMenu>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*chart card  */}
                <div style={{ marginTop: '2vh' }}>
                    <div style={{ display: 'flex', gap: '1vw' }}>
                        <div className={dashboardClasses.SecondRowCard}>
                            <ChartComponent
                                series={instanceGraphData.data}
                                title={`Last 30 min instance power data - ${instanceGraphData.instanceName} `}
                                xFormatter={timeOnly}
                                lastReported={selectedView.value === 'live' && `Refresh in ${oneMin} seconds`}
                                showLastReported={false}
                                height="80%"
                            />
                        </div>
                        <div className={dashboardClasses.SecondRowCard}>
                            <ChartComponent
                                series={instanceGraphData.consumptionData}
                                title={`Last 30 min instance consumption data - ${instanceGraphData.instanceName} `}
                                xFormatter={timeOnly}
                                yFormatter={formatYAxisValue}
                                lastReported={selectedView.value === 'live' && `Refresh in ${oneMin} seconds`}
                                showLastReported={false}
                                height="80%"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <ModalComponent isOpen={bidsModal} setOpen={setBidsModal}>
                <BidInfoModal data={bidHourlyData} setOpen={setBidsModal} />
            </ModalComponent>

            <ModalComponent isOpen={forceModal} setOpen={setForceModal}>
                <ForceFactorActivation instance={instance} market={edgeData?.market} setForceModal={setForceModal} getEdgeData={getEdgeData} />
            </ModalComponent>
        </div>
    );
};

export default Dashboard;
