// Standard library imports
import React, { useState, useEffect } from 'react';

// External library import
import { UilFileDownload } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks';
import Table from '../../../components/Table/Table';
import { EdgeService } from '../../../services/EdgeService';
import { momentTimeFormater } from '../../../utils/timeHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

// CSS Imports
import classes from '../EdgeError/EdgeError.module.css';
import bidClasses from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';

const EdgeFileUploadHistory = ({ edgeId, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const [totalCount, setTotalCount] = useState(0);

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        getUplodedFilesHistory();
    }, [edgeId]);

    const getUplodedFilesHistory = () => {
        if (edgeId) {
            EdgeService.GetUploadedFilesHistory(edgeId, startLoader, handleUploadedFileHistorySuccess, handleError, stopLoader);
        }
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleGetSignedUrl = (key) => {
        EdgeService.GetUploadedFileSignedURL({ key, type: 'edgeFile' }, startLoader, handleFileSignedURLSuccess, handleError, stopLoader);
    };

    const handleFileSignedURLSuccess = ({ data }) => {
        if (data.data?.signedUrl) {
            window.open(data.data.signedUrl, '_blank');
        }
    };

    const handleUploadedFileHistorySuccess = ({ data }) => {
        const items = data.data || [];
        const processedData = items.map((item, index) => {
            return {
                slNo: index + 1,
                name: item.name.length > 35 ? <CustomTooltip content={item.name}>{item.name.substring(0, 35) + '...'}</CustomTooltip> : item.name || '--',
                fileSaved: (
                    <div className={EdgeClasses.DataMonitoringContainer}>
                        {item.isDeleted ? <div className={EdgeClasses.AccessBlocked}></div> : <div className={EdgeClasses.AccessNotBlocked}></div>}
                    </div>
                ),
                createdAt: item.createdAt ? momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                updatedAt: item.updatedAt ? momentTimeFormater(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                action: (
                    <div>
                        <CustomTooltip content={'Download file'}>
                            <UilFileDownload size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => handleGetSignedUrl(item.key)} />
                        </CustomTooltip>
                    </div>
                ),
            };
        });
        setTableData(processedData);
        setTotalCount(processedData.length);
    };

    return (
        <div className={bidClasses.Bids}>
            <div className={classes.Header}>
                <div className={classes.ErrorDropdownContainer}>
                    <Typography content={'Edge Uploaded Files History'} />
                    <div className={bidClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <Table
                    head={['Sl.No', 'File Name', 'File Saved', 'CREATED AT (CET)', 'UPDATED AT (CET)', 'ACTION']}
                    keys={['slNo', 'name', 'fileSaved', 'createdAt', 'updatedAt', 'action']}
                    data={tableData}
                />
            </div>

        </div >
    );
};

export default EdgeFileUploadHistory;
