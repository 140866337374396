// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { MenuItem, Menu, MenuDivider } from '@szhsin/react-menu';
import { UilCopy, UilInfoCircle, UilImport, UilBolt, UilTrash, UilCapture, UilSync, UilKeyboardShow, UilBan, UilNotes } from '@iconscout/react-unicons';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

// Internal module imports
import FilterBarClasses from '../../../../styles/Filterbar.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { regulationsService } from '../../../../services/regulationsService';
import { useLoader } from '../../../../hooks';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../../styles/Bids.module.css';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { AdminService } from '../../../../services/AdminService';
import { RootBundleBiddingService } from '../../../../services/RootBundleBidService';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import CreateBundleBid from '../../../Bidding/BundleBidding/ModalComponent/CreateBundleBid';
import BiddingInfoContent from '../../../Bidding/BundleBidding/ModalComponent/BiddingInfo';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import CreateSecondaryBid from '../../../Bidding/BundleBidding/ModalComponent/CreateSecondaryBid';
import Status from '../../../Bidding/BundleBidding/ModalComponent/Status';
import ResultId from '../../../Bidding/BundleBidding/ModalComponent/ResultId';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { Panic } from '../../../Bidding/BundleBidding/ModalComponent/Panic';
import { NotesLogsIndex } from '../../../Bidding/BundleBidding/ModalComponent/NotesLogsIndex';
import { AFRR_MARKET_TYPES } from '../../../../constants';

const LIMIT = 15;

const FILTER_OPTIONS = [
    { label: 'All Bids', value: 'all' },
    { label: 'No Bids', value: 'noBids' },
];

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((a) => options.push({ label: a.name, value: a._id }));
    return options;
};

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

export const BundleBidding = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [regulations, setRegulations] = useState([]);
    const [selectedRegulation, setSelectedRegulation] = useState({
        value: queryParamsData.regulation,
    });
    const [date, setDate] = useState(moment(queryParamsData.bidDate || undefined).toDate());
    const [admins, setAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({
        value: queryParamsData.admin,
    });
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({
        value: queryParamsData.customer,
    });

    const [selectedFilter, setSelectedFilter] = useState(queryParamsData?.filter ? { value: queryParamsData?.filter } : FILTER_OPTIONS[0]);

    const [bidsData, setBidsData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [createBundleBidModal, setCreateBundleBidModal] = useState({
        status: false,
        meta: {},
    });

    const [biddingInfoModal, setBiddingInfoModal] = useState({
        status: false,
    });

    const [dMinusOnemodal, setDMinusOneModal] = useState({
        status: false,
        bid: {},
    });

    const [statusModal, setStatusModal] = useState({
        status: false,
        id: {},
    });

    const [resultIdModal, setResultIdModal] = useState({
        status: false,
        data: {},
    });

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: '',
        type: 'primary',
    });

    const [panicModal, setPanicModal] = useState({
        status: false,
        data: {},
    });

    const [notesModal, setNotesModal] = useState({
        status: false,
        data: {},
    });
    const [tableHeaders, setTableHeaders] = useState([]);
    useEffect(() => {
        fetchRegulations();
        fetchAdmins();
    }, []);

    useEffect(() => {
        if (!selectedAdmin.value) return;
        if (selectedAdmin.value !== 'All') fetchAdminCustomers();
        else {
            const options = getCustomerOptions([]);
            setCustomers(options);
            setSelectedCustomer(options[0]);
        }
    }, [selectedAdmin.value]);

    useEffect(() => {
        if (selectedRegulation.value) {
            fetchBids();

            if (AFRR_MARKET_TYPES.includes(selectedRegulation.value)) {
                setTableHeaders(['Customer Name', 'Bundle Name', 'Avg Capacity', 'Revenue (€)', 'Status(Capacity/Energy)', 'Action']);
            } else {
                setTableHeaders(['Customer Name', 'Bundle Name', 'Avg Capacity', 'Revenue (€)', 'Status(Primary/Secondary)', 'Action']);
            }
        }
    }, [skip, selectedCustomer.value, selectedRegulation.value, selectedAdmin.value]);

    const fetchRegulations = () => {
        regulationsService.ReadAll(
            () => startLoader('load regulations'),
            handleRegulationSuccess,
            handleError,
            () => stopLoader('load regulations')
        );
    };

    const handleRegulationSuccess = ({ data }) => {
        if (Array.isArray(data.data) && data.data.length) {
            const processedReguations = data.data.map((r) => ({
                label: r.name,
                value: r.name,
            }));
            setRegulations(processedReguations);
            if (!selectedRegulation.value) setSelectedRegulation(processedReguations[0]);
        }
    };

    const fetchAdmins = () => {
        AdminService.ReadAll(
            () => startLoader('get admin'),
            handleFetchSucess,
            handleError,
            () => stopLoader('get admin')
        );
    };

    const handleFetchSucess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getAdminOptions(data.data);
            setAdmins(options);
            if (!selectedAdmin.value) setSelectedAdmin(options[0]);
        }
    };

    const fetchAdminCustomers = () => {
        AdminService.GetMarketParticipatingCustomers(
            selectedAdmin.value,
            () => startLoader('get customer'),
            handleCustomersSuccess,
            handleError,
            () => stopLoader('get customer')
        );
    };

    const handleCustomersSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getCustomerOptions(data.data);
            setCustomers(options);
            if (!selectedCustomer.value) setSelectedCustomer(options[0]);
        }
    };

    const fetchBids = () => {
        const params = {
            limit: LIMIT,
            skip: skip,
            market: selectedRegulation.value,
            bidDate: moment(date).format('YYYY-MM-DD'),
            adminId: selectedAdmin.value,
            customerId: selectedCustomer.value,
            filter: selectedFilter?.value,
        };
        RootBundleBiddingService.GetBundleBids(
            params,
            () => startLoader('get bids'),
            handleBidsSuccess,
            handleError,
            () => stopLoader('get bids')
        );
    };

    const handleDownloadHourData = (item) => {
        if (item.market !== 'FFR') {
            let totalDMinus2Price = 0;
            let totalDMinus1Price = 0;
            let totalPrice = 0;

            let capacity = {};
            let secCapacity = {};

            let price = [];
            let secPrice = [];

            _.get(item, ['bids'], []).map((b, bIdx) => {
                _.set(capacity, [b.instanceId], _.get(b, ['primary', 'capacity'], []));
                _.set(secCapacity, [b.instanceId], _.get(b, ['secondary', 'capacity'], []));

                Array(24)
                    .fill(0)
                    .map((p, pIdx) => {
                        _.set(price, [pIdx], _.get(price, [pIdx], 0) + _.get(b, ['primary', 'price', pIdx], 0));
                        _.set(secPrice, [pIdx], _.get(secPrice, [pIdx], 0) + _.get(b, ['secondary', 'price', pIdx], 0));
                    });
            });

            const sumOfCapacity = getSumCapacity(capacity);
            const sumOfCapacityDMinus1 = getSumCapacity(secCapacity);

            let data = Array(24)
                .fill(null)
                .map((i, index) => {
                    const dMinus2price = _.get(item, ['primary', 'provider', 'bidStatus', index, 'price'], '--');
                    const dMinus1price = _.get(item, ['secondary', 'provider', 'bidStatus', index, 'price'], '--');
                    const hourPrice = _.get(item, ['bidStatus', index, 'price'], 0);

                    if (dMinus2price >= 0) totalDMinus2Price += dMinus2price;
                    if (dMinus1price >= 0) totalDMinus1Price += dMinus1price;
                    if (hourPrice >= 0) totalPrice += hourPrice;

                    return {
                        hour: index,
                        capacityDMinus2: _.get(sumOfCapacity, [index], '--'),
                        priceDMinus2: _.get(price, [index], '--'),
                        settlementDMinus2: dMinus2price,
                        capacityDMinus1: _.get(sumOfCapacityDMinus1, [index], '--'),
                        priceDMinus1: _.get(secPrice, [index], '--'),
                        settlementDMinus1: dMinus1price,
                        hourcost: hourPrice,
                    };
                });

            data.push({
                hour: 'Total',
                capacityDMinus2: '',
                priceDMinus2: '',
                settlementDMinus2: totalDMinus2Price,
                capacityDMinus1: '',
                priceDMinus1: '',
                settlementDMinus1: totalDMinus1Price,
                hourcost: totalPrice,
            });

            DownloadAsExcel(data, `FCR-D_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, [
                'Hour',
                'Capacity (MW) (D-2)',
                'Price (€) (D-2)',
                'Settlement (€) (D-2)',
                'Capacity (MW) (D-1)',
                'Pricem (€) (D-1)',
                'Selttlement (€) (D-1)',
                'Settlement (€) ( D-2 & D-1)',
            ]);
        } else {
            let data = _.get(item, ['capacity'], []).map((i, index) => ({
                hour: index,
                capacity: i,
                price: _.get(item, ['bids', '0', 'price', index], '--'),
                hourcost: _.get(item, ['bidStatus', index, 'price'], '--'),
            }));
            DownloadAsExcel(data, `FFR_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
        }
    };

    const getSumCapacity = (capacity) => {
        let isCap = false;
        let data = Array(24)
            .fill(null)
            .map((i, index) => {
                let sum = 0;
                for (let key in capacity) {
                    if (capacity[key].length) {
                        sum += Number(capacity[key]?.[index]);
                        isCap = true;
                    }
                }
                return isCap ? sum : '--';
            });
        return data;
    };

    const handleBidsSuccess = ({ data }) => {
        if (Array.isArray(data.data.data)) {
            const processedData = data.data.data.map((item) => {
                item.action = (
                    <div className={classes.ActionContainer}>
                        {!item.isBidPresent ? (
                            <CustomTooltip content={'Create Bid'}>
                                <div
                                    className={classes.Action}
                                    onClick={() => {
                                        if (item.isAgreement) {
                                            setCreateBundleBidModal({
                                                status: true,
                                                meta: {
                                                    customerId: item.customerId,
                                                    instanceBundle: {
                                                        label: item.instanceBundleName,
                                                        value: item.instanceBundleId,
                                                        pairs: item.pairs || [],
                                                    },
                                                    market: item.market,
                                                },
                                            });
                                        } else {
                                            toast.error('No Agreement Found.');
                                        }
                                    }}
                                >
                                    <div>
                                        <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        ) : (
                            <>
                                <CustomTooltip content={'Bidding Info'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            let hourlyCost = {
                                                bidStatus: item.bundleBid.bidStatus,
                                                energyStatus: item.bundleBid.energyStatus || [],
                                            };
                                            setBiddingInfoModal({
                                                status: true,
                                                hourlyCost,
                                                bids: item.bundleBid.bids,
                                                overallCapacity: item.bundleBid.capacity,
                                                overallPrice: item.bundleBid.price,
                                                panicHours: _.get(item, ['bundleBid', 'panic', 'hours'], []),
                                            });
                                        }}
                                    >
                                        <div>
                                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>

                                <CustomTooltip content={'Download'}>
                                    <div className={classes.Action} onClick={() => handleDownloadHourData(item.bundleBid)}>
                                        <div>
                                            <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>

                                {(item.market === 'FCR-D-INC' || item.market === 'FCR-D-DEC') && _.get(item, ['bundleBid', 'secondary', 'isDeleted']) && (
                                    <CustomTooltip content={'Secondary Bid'}>
                                        <div className={classes.Action} onClick={() => setDMinusOneModal({ status: true, bid: item.bundleBid })}>
                                            <div>
                                                <UilSync size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </div>
                                    </CustomTooltip>
                                )}
                                <CustomTooltip content={'Fetch Status'}>
                                    <div className={classes.Action} onClick={() => setStatusModal({ id: item.bundleBid._id, status: true })}>
                                        <div>
                                            <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>
                                <CustomTooltip content={'Result ID'}>
                                    <div className={classes.Action} onClick={() => setResultIdModal({ status: true, data: item.bundleBid })}>
                                        <div>
                                            <UilKeyboardShow size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>

                                <Menu
                                    menuButton={
                                        <div>
                                            <CustomTooltip content={'Delete'}>
                                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </CustomTooltip>
                                        </div>
                                    }
                                    direction={'left'}
                                    arrow={true}
                                >
                                    {!_.get(item, ['bundleBid', 'primary', 'isDeleted']) ? (
                                        <>
                                            <MenuItem
                                                onClick={() => {
                                                    setDeleteModal({ status: true, bidId: item.bundleBid._id, type: 'primary' });
                                                }}
                                                disabled={_.get(item, ['bundleBid', 'primary', 'provider', 'bidStatus'], []).length ? true : false}
                                            >
                                                <div>Delete Primary Bid {_.get(item, ['bundleBid', 'primary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                            </MenuItem>
                                            {!_.get(item, ['bundleBid', 'secondary', 'isDeleted']) ? <MenuDivider /> : ''}
                                        </>
                                    ) : (
                                        ''
                                    )}

                                    {!_.get(item, ['bundleBid', 'secondary', 'isDeleted']) ? (
                                        <MenuItem
                                            onClick={() => {
                                                setDeleteModal({ status: true, bidId: item.bundleBid._id, type: 'secondary' });
                                            }}
                                            disabled={_.get(item, ['bundleBid', 'secondary', 'provider', 'bidStatus'], []).length ? true : false}
                                        >
                                            <div>Delete Secondary Bid {_.get(item, ['bundleBid', 'secondary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                        </MenuItem>
                                    ) : (
                                        ''
                                    )}
                                </Menu>

                                {_.get(item, ['bundleBid', 'bidStatus'], []).length ? (
                                    <CustomTooltip content={'Panic'}>
                                        <div
                                            className={classes.Action}
                                            onClick={() => {
                                                setPanicModal({ status: true, data: item.bundleBid });
                                            }}
                                        >
                                            <div>
                                                <UilBan size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </div>
                                    </CustomTooltip>
                                ) : (
                                    ''
                                )}

                                <CustomTooltip content={'Bid Note'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setNotesModal({ status: true, data: item.bundleBid });
                                        }}
                                    >
                                        <div>
                                            <UilNotes size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>
                            </>
                        )}
                    </div>
                );
                if (!item.isBidPresent) {
                    item.style = { background: '#FFE3E3', color: '#E25657' };
                }
                if (_.get(item, ['bid', 'notes'], []).length) {
                    item.style = { background: '#fef6e5', color: '#faa500' };
                }
                item.status = `${item.primaryStatus}/${item.secondaryStatus}`;

                return item;
            });
            setBidsData(processedData);
        }
        setTotalCount(data.data.totalCount);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };
    const handleDelete = (id) => {
        RootBundleBiddingService.DeleteBundleBid(deleteModal.bidId, { type: deleteModal.type }, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = () => {
        fetchBids();
        toast.success('Bid Deleted');
    };

    return (
        <div>
            <div className={FilterBarClasses.ParentWrapper}>
                <div className={FilterBarClasses.Left}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Admin</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedAdmin}
                                options={admins}
                                onChange={(e) => {
                                    setSelectedAdmin(e);
                                    updateQueryParams('admin', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Customer</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedCustomer}
                                options={customers}
                                onChange={(e) => {
                                    setSelectedCustomer(e);
                                    updateQueryParams('customer', e.value);
                                }}
                            />
                        </div>
                    </div>

                    <div className={FilterBarClasses.Filter}>
                        <label>Market</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedRegulation}
                                options={regulations}
                                onChange={(e) => {
                                    setSelectedRegulation(e);
                                    updateQueryParams('regulation', e.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className={FilterBarClasses.Filter}>
                        <label>Filter</label>
                        <div style={{ width: '10vw' }}>
                            <DropdownComponent
                                defaultValue={selectedFilter}
                                options={FILTER_OPTIONS}
                                onChange={(e) => {
                                    setSelectedFilter(e);
                                    updateQueryParams('filter', e.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={FilterBarClasses.Right}>
                    <div className={FilterBarClasses.Filter}>
                        <label>Date</label>
                        <DatePicker
                            date={date}
                            setDate={(d) => {
                                setDate(d);
                                updateQueryParams('bidDate', d);
                            }}
                            showArrow={true}
                        />
                    </div>
                    <button
                        className="btn-primary"
                        style={{
                            marginTop: '0',
                            width: 'auto',
                        }}
                        onClick={fetchBids}
                    >
                        Submit
                    </button>
                </div>
            </div>

            <div>
                <Table
                    head={tableHeaders}
                    keys={['customerName', 'instanceBundleName', 'avgCapacity', 'revenue', 'status', 'action']}
                    data={bidsData}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
            <ModalComponent
                isOpen={createBundleBidModal.status}
                setOpen={() => {
                    setCreateBundleBidModal({ status: false, meta: {} });
                }}
                style={{ overflow: 'initial' }}
            >
                <div>
                    <CreateBundleBid
                        instanceBundle={createBundleBidModal.meta.instanceBundle}
                        customerId={createBundleBidModal.meta.customerId}
                        setOpen={() => {
                            setCreateBundleBidModal({ status: false, meta: {} });
                        }}
                        market={createBundleBidModal.meta.market}
                        getBundleBids={fetchBids}
                        bidDate={date}
                    />
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={biddingInfoModal.status}
                setOpen={() => {
                    setBiddingInfoModal({ status: false });
                }}
            >
                <BiddingInfoContent setOpen={setBiddingInfoModal} data={biddingInfoModal} />
            </ModalComponent>

            <ModalComponent isOpen={dMinusOnemodal.status} setOpen={() => setDMinusOneModal({ status: false, bid: {} })}>
                {dMinusOnemodal.status && (
                    <CreateSecondaryBid
                        bid={dMinusOnemodal.bid}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        setOpen={(e) => setDMinusOneModal({ status: false, bid: {} })}
                        getBundleBids={fetchBids}
                    />
                )}
            </ModalComponent>
            <ModalComponent isOpen={statusModal.status} setOpen={(e) => setStatusModal({ status: false })}>
                <Status bundleBidId={statusModal.id} setOpen={setStatusModal} getBundleBids={fetchBids} />
            </ModalComponent>

            <ModalComponent isOpen={resultIdModal.status} setOpen={() => setResultIdModal({ status: false, data: {} })}>
                <ResultId bid={resultIdModal.data} setModal={setResultIdModal} getBundleBids={fetchBids} />
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={panicModal.status}
                setOpen={() => {
                    setPanicModal({ status: false, data: {} });
                }}
            >
                <Panic data={panicModal.data} setOpen={setPanicModal} getBundleBids={fetchBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={notesModal.status}
                setOpen={() => {
                    setNotesModal({ status: false, data: null });
                }}
            >
                <NotesLogsIndex data={notesModal.data} setOpen={setNotesModal} />
            </ModalComponent>
        </div>
    );
};
