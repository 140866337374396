// Standard library imports
import React from 'react';
// Internal module imports
import Schedule from './Schedule';
import TestLogs from './TestLogsTable';
import TabsComponent from '../../../components/Tabs/Tabs';
import Control from './Control';

const ScheduleTestIndex = ({ edgeData, setScheduleModal, setRedirectTo, setTestLogs, setSelectedIndex, updateQueryParams, baseCommands }) => {
    const tabs = [
        {
            name: 'Schedule Test',
            component: (
                <>
                    <Schedule
                        edgeData={edgeData}
                        setScheduleModal={setScheduleModal}
                        setRedirectTo={setRedirectTo}
                        setTestLogs={setTestLogs}
                        setSelectedIndex={setSelectedIndex}
                        updateQueryParams={updateQueryParams}
                        baseCommands={baseCommands}
                    />
                </>
            ),
        },
        {
            name: 'Equipment Testing',
            component: (
                <>
                    <Control
                        edgeData={edgeData}
                        setScheduleModal={setScheduleModal}
                    />
                </>
            ),
        },
        {
            name: 'Predefined Test',
            component: (
                <>
                    <TestLogs market={edgeData.market} setScheduleModal={setScheduleModal} />
                </>
            ),
        },
    ];

    return (
        <div>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default ScheduleTestIndex;
