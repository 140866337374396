import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { FcrAggrementService } from '../../../services/fcrAggrement';
import classes from '../../../styles/AllDevices.module.css';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { capitalize } from 'lodash';
import moment from 'moment';

import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import { UilEye } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

function FCRAggrements({ setAggrement, customer, setSelectedTab }) {
    const [startLoader, stopLoader] = useLoader();
    const [aggrements, setAggrements] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        customerId: '',
        agreementId: '',
    });
    const [activeDaysModal, setActiveDaysModal] = useState({
        status: false,
        data: [],
    });

    const [instanceModal, setInstanceModal] = useState({
        status: false,
        data: [],
    });

    useEffect(() => {
        if (customer._id) {
            getData();
        }
    }, []);

    const getData = () => {
        FcrAggrementService.ReadAll({ customerId: customer._id }, startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const getEnablerSplit = (enablers = {}, enablersData = []) => {
        let totalRevenue = 0;
        let tooltip = [];
        enablersData.map((e) => {
            for (const key in enablers) {
                if (key == e._id) {
                    totalRevenue = totalRevenue + Number(enablers[key]);
                    tooltip.push(`${e.name} : ${enablers[key]}`);
                }
            }
        });
        return (
            <CustomTooltip
                content={tooltip.map((e) => (
                    <div>{e}</div>
                ))}
            >
                {totalRevenue}
            </CustomTooltip>
        );
    };

    const handleFetchSucess = ({ data }) => {
        let newData = data?.data.map((item, index) => ({
            ...item,
            's.no': index + 1,
            startDate: moment(item.startDate).format('DD-MM-YYYY'),
            endDate: item.endDate !== '--' ? moment(item.endDate).format('DD-MM-YYYY') : '--',
            instances: (
                <div>
                    <UilEye
                        size={'1.2vw'}
                        style={{ color: 'var(--color-primary)' }}
                        onClick={() => {
                            setInstanceModal({
                                status: true,
                                data: item?.instancesDetatails.map((ins) => {
                                    return {
                                        license: ins?.licenseName[0] ? capitalize(ins?.licenseName[0]) : '--',
                                        instance: ins?.name || '--',
                                        revenueSplit: item?.instances[ins._id]?.revenueSplit || '--',
                                        revenueSplitPartner: item?.instances[ins._id]?.revenueSplitPartner || '--',
                                        revenueSplitCustomer: item?.instances[ins._id]?.revenueSplitCustomer || '--',
                                        enablersSplit: getEnablerSplit(item?.instances[ins._id]?.enablers, item.enablersData),
                                        activeDays: (
                                            <div>
                                                <UilEye
                                                    size={'1.2vw'}
                                                    style={{ color: 'var(--color-primary)' }}
                                                    onClick={() => {
                                                        setActiveDaysModal({
                                                            status: true,
                                                            data: Object.keys(item?.instances[ins._id]?.activeDays).map((day) => {
                                                                return {
                                                                    day: capitalize(day),
                                                                    startHour: item?.instances[ins._id]?.activeDays[day]?.startHour
                                                                        ? item?.instances[ins._id]?.activeDays[day]?.startHour
                                                                        : item?.instances[ins._id]?.activeDays[day]?.startHour === 0
                                                                          ? 0
                                                                          : '--',
                                                                    endHour: item?.instances[ins._id]?.activeDays[day]?.endHour
                                                                        ? item?.instances[ins._id]?.activeDays[day]?.endHour
                                                                        : item?.instances[ins._id]?.activeDays[day]?.endHour === 0
                                                                          ? 0
                                                                          : '--',
                                                                    startHour1: item?.instances[ins._id]?.startHour1
                                                                        ? item?.instances[ins._id]?.activeDays[day]?.startHour1
                                                                        : item?.instances[ins._id]?.activeDays[day]?.startHour1 === 0
                                                                          ? 0
                                                                          : '--',
                                                                    endHour1: item?.instances[ins._id]?.activeDays[day]?.endHour1
                                                                        ? item?.instances[ins._id]?.activeDays[day]?.endHour1
                                                                        : item?.instances[ins._id]?.activeDays[day]?.endHour1 === 0
                                                                          ? 0
                                                                          : '--',
                                                                };
                                                            }),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        ),
                                    };
                                }),
                            });
                        }}
                    />
                </div>
            ),
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <CustomTooltip content={'Edit'}>
                            <UilPen
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() => {
                                    setAggrement(item);
                                    setSelectedTab(5);
                                }}
                            />
                        </CustomTooltip>
                    </div>

                    <div>
                        <CustomTooltip content={'Delete'}>
                            <UilTrash
                                size={'1.2vw'}
                                style={{ color: 'var(--color-primary)' }}
                                onClick={() =>
                                    setDeleteModal({
                                        status: true,
                                        customerId: item.customerId,
                                        agreementId: item._id,
                                    })
                                }
                            />
                        </CustomTooltip>
                    </div>
                </div>
            ),
        }));
        setAggrements(newData);
    };

    const handleDelete = () => {
        FcrAggrementService.Delete({ customerId: deleteModal.customerId }, deleteModal.agreementId, startLoader, handledeleteSuccess, handleError, stopLoader);
    };

    const handledeleteSuccess = (res) => {
        if (res) {
            setDeleteModal({ status: false, customerId: '', agreementId: '' });
            toast.success('Aggrement deleted successfully');
            getData();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>

            <ModalComponent isOpen={instanceModal.status} setOpen={(status) => setInstanceModal({ ...instanceModal, status })}>
                <div style={{ width: '50vw' }}>
                    <Typography content={'Instance details'} />
                    <Table
                        head={['License', 'Instance', 'R. Split (SC)', 'R. Split (Partner)', 'R. Split (Customer)', 'R. Split (Enablers)', 'Active Days']}
                        keys={['license', 'instance', 'revenueSplit', 'revenueSplitPartner', 'revenueSplitCustomer', 'enablersSplit', 'activeDays']}
                        data={instanceModal.data}
                    />
                </div>
            </ModalComponent>

            <ModalComponent isOpen={activeDaysModal.status} setOpen={(status) => setActiveDaysModal({ ...activeDaysModal, status })}>
                <div style={{ width: '40vw' }}>
                    <Typography content={'Active days detail'} />
                    <Table
                        head={['Day', 'Start Hour Slot1', 'End Hour Slot1', 'Start Hour Slot2', 'End Hour Slot2']}
                        keys={['day', 'startHour', 'endHour', 'startHour1', 'endHour1']}
                        data={activeDaysModal.data}
                    />
                </div>
            </ModalComponent>

            <div className={classes.Header}>
                <div>
                    <Typography content="All Agreements" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={0} />
                        </span>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn-primary"
                        style={{ marginTop: '0' }}
                        onClick={() => {
                            setAggrement({});
                            setSelectedTab(5);
                        }}
                    >
                        Create Aggrement
                    </button>
                </div>
            </div>
            <div>
                <Table
                    head={['Start Date', 'End Date', 'Customer', 'Market', 'Instances', 'Action']}
                    keys={['startDate', 'endDate', 'customerName', 'market', 'instances', 'action']}
                    data={aggrements}
                />
            </div>
        </div>
    );
}

export default FCRAggrements;
