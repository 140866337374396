// Standard library imports
import React from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import { Input } from '../../../../../components/Inputs/Input';
import Typography from '../../../../../components/Typography/Typography';
import { useLoader } from '../../../../../hooks';
import { RootAfrrService } from '../../../../../services/RootAfrrService';
import { updateValue } from '../../../../../validations/Root/afrrValidation';

const UpdateRedisValue = ({ data, setOpen, fetchValues }) => {
    const [startLoader, stopLoader] = useLoader();

    const handleSubmit = (val) => {
        const payload = {
            value: val.value,
            key: data.key,
            eicCode: data.eicCode,
        };
        RootAfrrService.UpdateRedisValue(payload, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = () => {
        toast.success('Value updated.');
        fetchValues();
        setOpen({
            status: false,
            data: {},
        });
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div style={{ width: '30vw' }}>
            <div>
                <Typography content={'Update Redis Value'} />
            </div>
            <div className={classes.FormContent}>
                <Formik
                    initialValues={{
                        value: data?.value || '',
                    }}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={updateValue}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl2}>
                                        <label>
                                            Value <span className="required">*</span>
                                        </label>
                                        <Input type={'text'} name="value" style={{ marginTop: '0' }} />
                                    </div>
                                </div>

                                <div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button
                                                className="btn-secondary"
                                                onClick={() => {
                                                    setOpen({ status: false, data: {} });
                                                }}
                                                type="button"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default UpdateRedisValue;
