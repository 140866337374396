import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const EICCodeCreate = yup.object().shape({
    name: yup.string().required(ERRORS.required),
    EICCode: yup.string().required(ERRORS.required),
    monitoring: yup.boolean().required(ERRORS.required),
    redisConfig: yup.object().shape({
        host: yup.string().required(ERRORS.required),
        port: yup.number().required(ERRORS.required),
        password: yup.string().optional(),
    }),
});
