// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment';

// Internal module imports
import AfrrClasses from './index.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import classes from '../../../../styles/AllDevices.module.css';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { useLoader } from '../../../../hooks';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import ChartComponent from '../../../../components/ApexCharts/Chart';
import { timeOnlyWithms } from '../../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../../utils/currencyHelper';

const EicSignalLogs = ({ queryParamsData, updateQueryParams, eicCodeOptions }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [selectedEicCode, setSelectedEicCode] = useState({});
    const [chartData, setChartData] = useState([]);
    const [lastReported, setLastReported] = useState('');

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (eicCodeOptions?.length) setSelectedEicCode(queryParamsData.eicCode ? { value: queryParamsData.eicCode } : eicCodeOptions[0]);
    }, [eicCodeOptions]);

    useEffect(() => {
        if (selectedEicCode?.value) {
            getEicSignalLogs();
        }
    }, [selectedEicCode]);

    const getEicSignalLogs = () => {
        const params = {
            eicCode: selectedEicCode.value,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            startTime: startTime,
            endTime: endTime,
        };
        RootAfrrService.GetEicSignalLogs(params, startLoader, handleLogsSuccess, handleError, stopLoader);
    };

    const handleLogsSuccess = ({ data }) => {
        setChartData(data?.data?.data || []);
        setLastReported(data?.data?.lastReported ? momentTimeFormater(data?.data?.lastReported?.timestamp).format('YYYY-MM-DD HH:mm:ss') : '');
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    return (
        <div>
            <div>
                <div className={AfrrClasses.Filter}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={AfrrClasses.FilterContainer}>
                            <div>
                                <div className={classes.FieldControl}>
                                    <label>Select EIC Code</label>
                                    <DropdownComponent
                                        name="eicCode"
                                        options={eicCodeOptions}
                                        defaultValue={selectedEicCode}
                                        onChange={(e) => {
                                            if (e.value !== selectedEicCode?.value) {
                                                setSelectedEicCode(e);
                                            }
                                            updateQueryParams('eicCode', e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={AfrrClasses.FilterContainer}>
                            <div className={AfrrClasses.LeftContainer}>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>Start Time</label>
                                    <CustomTimePicker
                                        startTime={startTime}
                                        setStartTime={(e) => {
                                            setStartTime(e);
                                            updateQueryParams('startTime', e);
                                        }}
                                        type="start"
                                        maxDetail={'second'}
                                    />
                                </div>
                                <div className={AfrrClasses.SelecterContainer}>
                                    <label className={AfrrClasses.DatePickerLabel}>End Time</label>
                                    <CustomTimePicker
                                        endTime={endTime}
                                        setEndTime={(e) => {
                                            setEndTime(e);
                                            updateQueryParams('endTime', e);
                                        }}
                                        type="end"
                                        maxDetail={'second'}
                                    />
                                </div>
                                <div style={{ display: 'flex', gap: '0.5vw' }}>
                                    <div onClick={getEicSignalLogs}>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={AfrrClasses.EicChartContainer}>
                    {chartData.map((chart, idx) => (
                        <div key={Math.random()}>
                            <ChartComponent
                                series={chart}
                                title={chart[0].name}
                                type="line"
                                xFormatter={timeOnlyWithms}
                                lastReported={lastReported}
                                yFormatter={formatYAxisValue}
                                chartProps={{
                                    group: 'signalLog',
                                    // id: `${Math.random()}`,
                                }}
                                height="80%"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EicSignalLogs;
