// Standard library imports
import React, { useState, useEffect } from 'react';

// External library import
import { UilEye, UilCopy } from '@iconscout/react-unicons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks';
import Table from '../../../components/Table/Table';
import { EdgeService } from '../../../services/EdgeService';
import { momentTimeFormater } from '../../../utils/timeHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';

// CSS Imports
import classes from '../EdgeError/EdgeError.module.css';
import bidClasses from '../../../styles/Bids.module.css';
import bidsClasses from '../../../styles/BidModal.module.css';

const EdgeConfigHistory = ({ deviceId, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const [totalCount, setTotalCount] = useState(0);

    const [tableData, setTableData] = useState([]);
    const [edgeConfigModal, setEdgeConfigModal] = useState({ status: false, data: {} });

    useEffect(() => {
        getEdgeConfigHistory();
    }, [deviceId]);

    const getEdgeConfigHistory = () => {
        if (deviceId) {
            EdgeService.GetEdgeConfigHistory(deviceId, startLoader, handleEdgeConfigHistorySuccess, handleError, stopLoader);
        }
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleEdgeConfigHistorySuccess = ({ data }) => {
        const items = data.data?.properties?.edgeConfigurationHistory || [];
        const processedData = items.map((item, index) => {
            return {
                slNo: index + 1,
                createdAt: item.createdAt ? momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                action: (
                    <div>
                        <CustomTooltip content={'Preview Edge Configuration'}>
                            <UilEye size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setEdgeConfigModal({ status: true, data: item.data })} />
                        </CustomTooltip>
                    </div>
                ),
            };
        });
        setTableData(processedData);
        setTotalCount(processedData.length);
    };

    return (
        <div className={bidClasses.Bids}>
            <div className={classes.Header}>
                <div className={classes.ErrorDropdownContainer}>
                    <Typography content={'Edge Configuration History'} />
                    <div className={bidClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <Table
                    head={['Sl.No', 'CREATED AT (CET)', 'ACTION']}
                    keys={['slNo', 'createdAt', 'action']}
                    data={tableData}
                />
            </div>

            <ModalComponent isOpen={edgeConfigModal.status} setOpen={() => setEdgeConfigModal({ status: false, data: '' })}>
                <div style={{ minWidth: '60vw' }}>
                    <Typography content={'Preview'} />

                    <Formik initialValues={{}}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={bidsClasses.InputContainer2}>
                                        <div className={bidsClasses.FieldControl2} style={{ marginTop: '1vh' }}>
                                            <label
                                                for="edgeConfiguration"
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <span>Edge Configuration</span>
                                            </label>
                                            <NewJSONEditor
                                                name="edgeConfiguration"
                                                defaultValue={edgeConfigModal?.data || {}}
                                                height='30vw'
                                            />
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            marginTop: '0.5vw',
                            gap: '0.5vw',
                        }}
                    >
                        <CopyToClipboard text={JSON.stringify(edgeConfigModal?.data)} onCopy={() => toast.success('Copied!')}>
                            <button
                                style={{
                                    width: '7vw',
                                    margin: '0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '0.2vw',
                                }}
                                className="btn-primary"
                            >
                                <UilCopy
                                    size={'1vw'}
                                    style={{
                                        color: 'var(--color-secondary)',
                                        cursor: 'pointer',
                                        zIndex: '1',
                                    }}
                                />
                                <span>
                                    Copy
                                </span>
                            </button>
                        </CopyToClipboard>

                        <button
                            onClick={() => {
                                setEdgeConfigModal({ status: false, data: '' })
                            }}
                            style={{ width: '7vw', margin: '0' }}
                            className="btn-secondary"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </ModalComponent>
        </div >
    );
};

export default EdgeConfigHistory;
