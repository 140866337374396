// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilSync } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/AllDevices.module.css';
import { useLoader } from '../../../../hooks';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import afrrClasses from './index.module.css';
import { ToggleButtonWithState } from '../../../../components/Inputs/Input';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { UilPen } from '@iconscout/react-unicons';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import UpdateRedisValue from './ModalComponent/UpdateRedisValue';
import _ from 'lodash';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import AfrrClasses from './index.module.css';

const Dashboard = ({ queryParamsData, updateQueryParams, eicCodeOptions }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [seconds, setSeconds] = useState(10);
    const [counterInterval, setCounterInterval] = useState(null);
    const [updateRedisValueModal, setUpdateRedisValueModal] = useState({
        status: false,
        data: {},
    });
    const [selectedEicCode, setSelectedEicCode] = useState({});

    useEffect(() => {
        if (eicCodeOptions?.length) setSelectedEicCode(queryParamsData.eicCode ? { value: queryParamsData.eicCode } : eicCodeOptions[0]);
    }, [eicCodeOptions]);

    useEffect(() => {
        if (selectedEicCode?.value) getRedisData();
    }, [selectedEicCode]);

    const getRedisData = (showLoader = true) => {
        const params = {
            eicCode: selectedEicCode.value,
        };
        RootAfrrService.GetRedisData(params, showLoader ? startLoader : () => {}, handleSuccess, handleError, showLoader ? stopLoader : () => {});
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data.map((item, index) => {
            const parsedObject = JSON.parse(item.value);
            if (typeof parsedObject === 'object' && parsedObject !== null) {
                return {
                    sno: index + 1,
                    key: item.key,
                    value: (
                        <div>
                            <CustomTooltip content={parsedObject?.value}>
                                {parsedObject?.value?.length > 40 ? parsedObject?.value?.substring(0, 40) + '...' : parsedObject?.value}
                            </CustomTooltip>
                        </div>
                    ),
                    address:
                        typeof _.get(parsedObject, ['address'], '--') === 'string' || typeof _.get(parsedObject, ['address'], '--') === 'number'
                            ? _.get(parsedObject, ['address'], '--')
                            : '--',
                    timestamp: _.get(parsedObject, ['timestamp'], '--'),
                    action: (
                        <div>
                            <CustomTooltip content={'Update'}>
                                <UilPen
                                    onClick={() => {
                                        setUpdateRedisValueModal({
                                            status: true,
                                            data: {
                                                key: item.key,
                                                value: parsedObject.value,
                                                eicCode: selectedEicCode?.value,
                                            },
                                        });
                                    }}
                                    style={{ color: 'var(--color-primary)' }}
                                    size={'1.2vw'}
                                />
                            </CustomTooltip>
                        </div>
                    ),
                };
            } else {
                return {
                    sno: index + 1,
                    key: item.key,
                    value: 'Invalid Value',
                    address: '--',
                    timestamp: 'Invalid Value',
                    action: '--',
                };
            }
        });

        setData(processedData);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    getRedisData(false);
                    setSeconds(10);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    return (
        <div>
            <ModalComponent
                isOpen={updateRedisValueModal.status}
                setOpen={() => {
                    setUpdateRedisValueModal({ status: false, data: {} });
                }}
            >
                <UpdateRedisValue data={updateRedisValueModal.data} setOpen={setUpdateRedisValueModal} fetchValues={getRedisData} />
            </ModalComponent>
            <div className={AfrrClasses.Filter}>
                <div className={AfrrClasses.OuterContainer}>
                    <div className={AfrrClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select EIC Code</label>
                                <DropdownComponent
                                    name="eicCode"
                                    options={eicCodeOptions}
                                    defaultValue={selectedEicCode}
                                    onChange={(e) => {
                                        if (e.value !== selectedEicCode?.value) {
                                            setSelectedEicCode(e);
                                        }
                                        updateQueryParams('eicCode', e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={AfrrClasses.FilterContainer}>
                        <div className={afrrClasses.RefreshContainer}>
                            <div>
                                <CustomTooltip content={'Refresh'}>
                                    <div>
                                        <UilSync
                                            size={'1.5vw'}
                                            style={{
                                                cursor: 'pointer',
                                                marginTop: '0.4vw',
                                                color: 'var(--color-primary)',
                                            }}
                                            onClick={getRedisData}
                                        />
                                    </div>
                                </CustomTooltip>
                            </div>
                            <div className={afrrClasses.TimerContainer}>
                                <ToggleButtonWithState
                                    label={'Auto Refresh'}
                                    value={isAutoRefresh}
                                    style={{ marginTop: '0' }}
                                    onChange={(e) => {
                                        setIsAutorefresh(e.target.checked);
                                        clearInterval(counterInterval);
                                        setSeconds(10);
                                    }}
                                />
                                {isAutoRefresh && <label className={afrrClasses.TimerLabel}>in {seconds} seconds</label>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Redis Console" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={data.length || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table head={['S.No', 'Key', 'Value', 'Address', 'Timestamp', 'Action']} keys={['sno', 'key', 'value', 'address', 'timestamp', 'action']} data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
