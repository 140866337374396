// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment';
import { toast } from 'react-toastify';
import { UilInfoCircle } from '@iconscout/react-unicons';

// Internal module imports
import activationClasses from './ActivationProfiler.module.css';
import { getDuration } from '../../../utils/timeHelper';
import { truncateNumber } from '../../../utils/numberHelper';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { activationProfileService } from '../../../services/ActivationProfileService';
import { useLoader } from '../../../hooks';
import { signalsService } from '../../../services/signalsService';
import { ToggleButtonWithState } from '../../../components/Inputs/Input';
import { timeOnlyWithms } from '../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ThreeStateToggle from '../../../components/Inputs/ThreeStateToggle/ThreeStateToggle';

const CAPACITY_OPTIONS = {
    left: {
        title: 'Bid Capacity',
        value: 'left',
    },
    right: {
        title: 'Edge Capacity',
        value: 'right',
    },
};

const Power = ({
    date,
    setDate,
    startTime,
    endTime,
    setStartTime,
    setEndTime,
    profile,
    onSubmit,
    setIsAutorefresh,
    isAutoRefresh,
    setIsError = null,
    style = {},
    showAutoRefreshToggle = true,
}) => {
    const [activationLogs, setActivationLogs] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [powerData, setPowerData] = useState([]);
    const [setPointData, setSetPointData] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [lastStatus, setLastStatus] = useState('--');
    const [disableAutoRefresh, setDisableAutoResh] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [counterInterval, setCounterInterval] = useState(null);
    const [edgeCapacity, setEdgeCapacity] = useState(true);

    useEffect(() => {
        if (profile?.value) {
            getActivationLog();
            getLastReported();
            getData();
        }
    }, [onSubmit, profile, edgeCapacity]);

    const getData = (activationStartTime = null, activationEndTime = null) => {
        const params = {
            start: activationStartTime ? activationStartTime : startTime,
            end: activationEndTime ? activationEndTime : endTime,
            date: new Date(moment(date).tz('Europe/Berlin').format()),
            prefillData: false,
            dataDecimation: true,
            edgeId: profile?.value,
            isEdgeCapacity: edgeCapacity,
        };

        signalsService.getActivationProfile(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        if (data?.data?.message) {
            setIsError && setIsError(true);
            toast.error(data?.data?.message);
        } else {
            setIsError && setIsError(false);
            setGraphData(data.data?.frequency);
            setPowerData(data.data?.power);
            setSetPointData(data.data?.setPoint || []);
        }
    };

    const getActivationLog = () => {
        const params = {
            id: profile.value,
            date: date,
        };
        activationProfileService.GetActivation(params, startLoader, handleActivationLogSuccess, handleError, stopLoader);
    };

    const handleActivationLogSuccess = ({ data }) => {
        setActivationLogs(data?.data || []);
    };

    const getLastReported = () => {
        if (profile?.signalId) {
            signalsService.getLastReportedData({ signalId: profile?.signalId }, startLoader, handleLastReportedSuccess, handleError, stopLoader);
        }
    };

    const handleLastReportedSuccess = ({ data }) => {
        if (data.data) {
            setLastStatus(moment(data?.data).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setLastStatus('--');
        }
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const setActivationTime = (startTime, endTime) => {
        setStartTime(moment(startTime).subtract(3, 'seconds').tz('Europe/Berlin').format('HH:mm:ss'));
        setEndTime(moment(endTime).add(1, 'minutes').tz('Europe/Berlin').format('HH:mm:ss'));
        getData(moment(startTime).subtract(3, 'seconds').tz('Europe/Berlin').format('HH:mm:ss'), moment(endTime).add(1, 'minutes').tz('Europe/Berlin').format('HH:mm:ss'));
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    const autoRefeshData = () => {
        setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'));
        const params = {
            start: startTime,
            end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'),
            date: moment(date).format('YYYY-MM-DD'),
            prefillData: false,
            dataDecimation: true,
            edgeId: profile?.value,
            isEdgeCapacity: edgeCapacity,
        };
        signalsService.AutoRefreshActivationProfile(params, handleSuccess, handleError);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm:ss');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    const getChipsColor = (chip) => {
        let color = {};
        const timeToReact = chip.secToReachNonZeroPower && Number(chip.secToReachNonZeroPower);
        if (chip.status === 'Activation Successful') {
            if (chip?.maxFrequency >= profile?.frequency?.p120 || chip?.minFrequency <= profile?.frequency?.p80) {
                if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                    color = {
                        background: 'linear-gradient(to right, #147439 95%, #FFA500 5%)',
                    };
                } else {
                    color = { background: '#147439' };
                }
            } else {
                if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                    color = {
                        background: 'linear-gradient(to right, #1FB559 95%, #FFA500 5%)',
                    };
                } else {
                    color = { background: '#1FB559' };
                }
            }
        } else if (
            chip.status === 'Under Delivered' ||
            chip.status === 'Over Delivered' ||
            chip.status === 'No Power' ||
            chip.status === 'Power & no Command' ||
            chip.status === 'No Activation'
        ) {
            if ((timeToReact || timeToReact === 0) && timeToReact > 3) {
                color = {
                    background: 'linear-gradient(to right, red 95%, #FFA500 5%)',
                };
            } else {
                color = { background: 'red' };
            }
        }
        return color;
    };

    return (
        <div>
            <div>
                <div className={activationClasses.chipsContainer}>
                    {activationLogs.map((item) => (
                        <div className={activationClasses.chips} onClick={() => setActivationTime(item.startTime, item.endTime)} style={getChipsColor(item)}>
                            {moment(item.startTime).tz('Europe/Berlin').format('HH:mm:ss')} - {getDuration(item.startTime, item.endTime)}
                            <br />
                            {item?.maxFrequency > 0 ? (
                                <span>Max Frequency - {truncateNumber(Number(item?.maxFrequency), 3)}</span>
                            ) : item?.minFrequency < 100 ? (
                                <span>Min Frequency - {truncateNumber(Number(item?.minFrequency), 3)}</span>
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div style={style}>
                <div>
                    <ChartComponent
                        series={graphData}
                        title={`${profile?.signalName}`}
                        type="line"
                        xFormatter={timeOnlyWithms}
                        lastReported={lastStatus}
                        yFormatter={formatYAxisValue}
                        chartProps={{
                            group: 'signalLog',
                        }}
                        rightSideElement={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '0.5vw',
                                }}
                            >
                                {showAutoRefreshToggle && (
                                    <ToggleButtonWithState
                                        disabled={disableAutoRefresh}
                                        label={'Auto Refresh'}
                                        value={isAutoRefresh}
                                        style={{ marginTop: '0' }}
                                        onChange={(e) => {
                                            setIsAutorefresh(e.target.checked);
                                            clearInterval(counterInterval);
                                            setSeconds(30);
                                        }}
                                    />
                                )}
                                {isAutoRefresh && (
                                    <label
                                        style={{
                                            fontSize: '0.72vw',
                                            fontWeight: '500',
                                            marginLeft: '-4px',
                                        }}
                                    >
                                        {!showAutoRefreshToggle && `Auto refresh`} in {seconds} seconds
                                    </label>
                                )}
                            </div>
                        }
                    />
                </div>
                <div style={setPointData.length ? { display: 'block' } : { display: 'none' }}>
                    <ChartComponent
                        series={setPointData}
                        title={'Set Point'}
                        type="line"
                        xFormatter={timeOnlyWithms}
                        lastReported={lastStatus}
                        yFormatter={formatYAxisValue}
                        chartProps={{
                            group: 'signalLog',
                        }}
                    />
                </div>
                <div>
                    <ChartComponent
                        series={powerData}
                        title={'Power'}
                        type="line"
                        xFormatter={timeOnlyWithms}
                        lastReported={lastStatus}
                        yFormatter={formatYAxisValue}
                        chartProps={{
                            group: 'signalLog',
                        }}
                        rightSideElement={
                            <ThreeStateToggle
                                labels={CAPACITY_OPTIONS}
                                onChange={(e) => {
                                    switch (e) {
                                        case 'left': {
                                            setEdgeCapacity(false);
                                            break;
                                        }
                                        case 'right': {
                                            setEdgeCapacity(true);
                                            break;
                                        }
                                    }
                                }}
                                defaultValue={'right'}
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Power;
