// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import AfrrLogs from './AfrrLogs';
import PowerLogs from './PowerLogs';

const CommandLog = ({ queryParamsData, updateQueryParams, eicCodeOptions }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.nestedTab ? queryParamsData.nestedTab : 0);
    let tabs = [
        {
            name: 'AFRR Logs',
            component: (
                <>
                    <AfrrLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'AFRR Power Logs',
            component: (
                <>
                    <PowerLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} eicCodeOptions={eicCodeOptions} />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('nestedTab', index);
                }}
            />
        </div>
    );
};

export default CommandLog;
